<template>
  <div class="body-container">
    <div class="d-flex flex-column">
      <div class="d-flex flex-column justify-content-center text-center primary-bg-colour primary-text make-predictions-header-container">
        <img @click="backButtonClicked" :src="require(`../brand/${brandConfig}/assets/left-keyboard-arrow.png`)" class="go-back-key">
        {{ formatString('league_bar_predict_text') }} {{ this.selectedSport }}
      </div>
      <div class="d-flex flex-column justify-content-center text-center p-4 secondary-bg-colour primary-text predict-scores-header">
        {{ formatString('make_predictions_header_text') }}
      </div>
      <div class="d-flex flex-column align-items-center">
        <div @click="openCloseEarnPointsPopUp()" class="d-flex flex-row align-items-center justify-content-center col-6 mt-3 p-1 earn-points-button">
          {{ formatString('make_predictions_earn_points_button') }}
        </div>
      </div>
      <div class="d-flex flex-column align-items-center">
        <div class="d-flex flex-row justify-content-around round-date-time col-11 mt-3 p-1 secondary-bg-colour primary-text">
          <div>{{ formattedDate }}</div>
          <div>{{ formattedTime }}</div>
        </div>
        <div class="text-center col-11" v-for="(fixture, index) in fixturesArray" :key="index">
          <div class="make-prediction-team-margin-container" v-if="checkMatchNotStarted(fixture)">
            <div class="pt-2 winner-margin-text">
              {{ formatString('make_predictions_win_type_text_cricket') }}
            </div>
            <div class="d-flex flex-row justify-content-around align-items-center pt-1 pb-3">
              <div @click="teamSelectedToWin(fixture, fixture.Team1, 1)"
                   :class=" fixture.teamNumber === 1 || fixture.WinningTeamId === fixture.Team1Id ? 'd-flex flex-column justify-content-center align-items-center align-content-center team-badge-container-selected' : 'd-flex flex-column justify-content-center align-items-center align-content-center team-badge-container-default'">
                <img class="col-9 team-badge-img" :src="this.findImageModule(fixture.Team1Name)"/>
              </div>
              <div class="vs-text">vs</div>
              <div @click="teamSelectedToWin(fixture, fixture.Team2, 2)"
                   :class=" fixture.teamNumber === 2  || fixture.WinningTeamId === fixture.Team2Id ?  'd-flex flex-column justify-content-center align-items-center align-content-center team-badge-container-selected' : 'd-flex flex-column justify-content-center align-items-center align-content-center team-badge-container-default'">
                <img class="col-9 team-badge-img" :src="this.findImageModule(fixture.Team2Name)"/>
              </div>
            </div>
            <div v-if="fixture.isSelected" class="d-flex flex-row justify-content-around mt-2 mb-2">
              <div @click="onMarginClicked('Narrow Win', fixture,  1)"
                   :class=" fixture.index === 1 || fixture.BowlingWinMargin === 0  ? 'd-flex flex-column align-items-center justify-content-center col-4 m-1 win-margin-container-selected' : 'd-flex flex-column align-items-center justify-content-center col-4 m-1 win-margin-container-default'">
                <div
                    :class=" fixture.index === 1 || fixture.BowlingWinMargin === 0 ? 'col-10 win-margin-type-box-selected secondary-bg-colour primary-text' : 'col-10 win-margin-type-box-default secondary-bg-colour primary-text'">
                  {{ formatString('make_predictions_cricket_win_by_type_narrow') }}
                </div>
                <div class="win-margin-wicket-run-text">{{ formatString('make_predictions_cricket_win_by_text') }}</div>
                <div class="win-margin-wicket-run-text">{{ formatString('make_predictions_cricket_win_by_type_narrow_wickets') }}</div>
                <div class="win-margin-wicket-run-text">{{ formatString('make_predictions_cricket_win_by_type_narrow_runs') }}</div>
              </div>
              <div @click="onMarginClicked('Comfortable Win', fixture,  2)"
                   :class=" fixture.index === 2 || fixture.BowlingWinMargin === 10 ? 'd-flex flex-column align-items-center justify-content-center col-4 m-1 win-margin-container-selected' : 'd-flex flex-column align-items-center justify-content-center col-4 m-1 win-margin-container-default'">
                <div
                    :class=" fixture.index === 2  || fixture.BowlingWinMargin === 10 ? 'col-10 win-margin-type-box-selected secondary-bg-colour primary-text' : 'col-10 win-margin-type-box-default secondary-bg-colour primary-text'">
                  {{ formatString('make_predictions_cricket_win_by_type_comfortable') }}
                </div>
                <div class="win-margin-wicket-run-text">{{ formatString('make_predictions_cricket_win_by_text') }}</div>
                <div class="win-margin-wicket-run-text">{{ formatString('make_predictions_cricket_win_by_type_comfortable_wickets') }}</div>
                <div class="win-margin-wicket-run-text">{{ formatString('make_predictions_cricket_win_by_type_comfortable_runs') }}</div>
              </div>
              <div @click="onMarginClicked('Thrashing', fixture,  3)"
                   :class=" fixture.index === 3 || fixture.BowlingWinMargin === 25 ? 'd-flex flex-column align-items-center justify-content-center col-4 m-1 win-margin-container-selected' : 'd-flex flex-column align-items-center justify-content-center col-4 m-1 win-margin-container-default'">
                <div
                    :class=" fixture.index === 3 || fixture.BowlingWinMargin === 25 ? 'col-10 win-margin-type-box-selected secondary-bg-colour primary-text' : 'col-10 win-margin-type-box-default secondary-bg-colour primary-text'">
                  {{ formatString('make_predictions_cricket_win_by_type_thrashing') }}
                </div>
                <div class="win-margin-wicket-run-text">{{ formatString('make_predictions_cricket_win_by_text') }}</div>
                <div class="win-margin-wicket-run-text">{{ formatString('make_predictions_cricket_win_by_type_thrashing_wickets') }}</div>
                <div class="win-margin-wicket-run-text">{{ formatString('make_predictions_cricket_win_by_type_thrashing_runs') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column justify-content-center pt-3 col-8">
          <button @click="submitPredictionsButtonClicked" class="submit-predictions-button primary-bg-colour primary-text">{{ formatString('make_predictions_submit_button') }}</button>
        </div>
        <div class="secondary-text error-message-text pt-2" v-if="noTeamSelectedError">
          {{ formatString('make_predictions_no_team_selected') }}
        </div>
        <div class="secondary-text error-message-text pt-2" v-if="noWinMarginError">
          Error: No Win Margin Selected
        </div>
      </div>
    </div>
    <div v-if="earnPointsPopup" class="d-flex flex-column align-items-center earn-points-popup col-10 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <img @click="openCloseEarnPointsPopUp()" class="exit-popup-img" :src="require(`../brand/${this.brandConfig}/assets/exit-icon.png`)">
      <div class="text-center col-10 pt-2 pb-2 popup-header-breakdown-text">
        {{ formatString('make_predictions_earn_points_popup_header_text') }}
      </div>
      <table class="table table-borderless">
        <thead class="table-header">
        <tr>
          <th scope="col">{{ formatString('make_predictions_earn_points_table_header_description') }}</th>
          <th class="d-flex flex-row justify-content-end" scope="col">{{ formatString('make_predictions_earn_points_table_header_points') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th class="point-description" scope="row">{{ formatString('make_predictions_earn_points_table_row_correct_team') }}</th>
          <td class="d-flex flex-row justify-content-end">{{ pointsScoringBreakdown[0].Value }}</td>
        </tr>
        <tr>
          <th class="point-description" scope="row">{{ formatString('make_predictions_earn_points_table_row_win_margin_two') }}</th>
          <td class="d-flex flex-row justify-content-end">{{ pointsScoringBreakdown[1].Value }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {translationsFormatter} from "@/functions/translationsFormatter";
import PredictorService from "@/services/predictorService";
import moment from "moment";

export default {
  name: "MakePredictionsCricket",
  data() {
    return {
      fixturesArray: undefined,
      formattedTime: undefined,
      formattedDate: undefined,
      earnPointsPopup: false,
      noTeamSelectedError: false,
      pointsScoringBreakdown: undefined,
      winnerSelected: undefined,
      noWinMarginError: undefined,
    }
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'selectedSport', 'user', 'selectedSportsLeagues'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setSelectedSport']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders);
    },
    checkMatchNotStarted(fixture) {
      return moment(fixture.StartDate).isAfter(new Date()) === true;
    },
    backButtonClicked() {
      if (this.selectedSportsLeagues === undefined) {
        this.$router.push({name: 'SportSelection'});
      } else this.$router.push({name: 'LeagueSelection', params: {sportName: this.selectedSport.toLowerCase()}});
    },
    openCloseEarnPointsPopUp() {
      this.earnPointsPopup = this.earnPointsPopup === false;
    },
    findImageModule(teamName) {
      let teamNameReformat = teamName.split(" ").join("-").toLowerCase()
      try {
        return [require(`../assets/teamBadges/cricket/${teamNameReformat}-logo-icon.png`)]
      } catch (e) {
        return [require(`../assets/game/default-badge.png`)]
      }
    },
    teamSelectedToWin(fixture, teamId, teamNumber) {
      fixture.WinningTeamId = null;
      fixture.isSelected = true;
      fixture.teamNumber = teamNumber
      fixture.teamId = teamId;
    },
    onMarginClicked(margin, fixture, index) {
      fixture.index = null;
      fixture.BowlingWinMargin = null;
      fixture.index = index;
      const winMargins = this.setVictoryMargins(margin);
      fixture.battingWinMargin = winMargins.battingWinMargin
      fixture.bowlingWinMargin = winMargins.bowlingWinMargin
      fixture.BowlingWinMargin = winMargins.bowlingWinMargin;
    },
    setVictoryMargins(margin) {
      switch (margin) {
        case 'Narrow Win':
          return {
            bowlingWinMargin: 0,
            battingWinMargin: 1,
          };
        case 'Comfortable Win':
          return {
            bowlingWinMargin: 10,
            battingWinMargin: 3,
          };
        case 'Thrashing':
          return {
            bowlingWinMargin: 25,
            battingWinMargin: 6
          };
      }
    },
    async checkIfRoundPreviouslyEntered() {
      this.fixturesArray = [];
      this.leagueFixtures = await PredictorService.getLeagueFixtures(`${this.user.msisdn}`, `${this.$route.params.roundId}`);
      let userFixturesEnteredResponse = await PredictorService.getPlayerRoundPredictionsLive(`${this.user.msisdn}`, `${this.$route.params.roundId}`)

      if (userFixturesEnteredResponse.data.roundPredictions.length > 0) {
        this.fixturesArray = userFixturesEnteredResponse.data.roundPredictions;
        this.setMarginVictory();
      } else {
        this.addRoundFixturesDefaultWinMargin(this.leagueFixtures.data.fixtures);
      }
      this.pointsScoringBreakdown = this.leagueFixtures.data.pointsScoringBreakdown;
      this.setSelectedSport('Cricket')
    },
    setMarginVictory() {
      this.fixturesArray.forEach((fixture) => {
        fixture.isSelected = true
      })
    },
    dateConversion(fixtures) {
      let fixtureStartDate = fixtures[0].StartDate
      this.formattedDate = moment(fixtureStartDate).format('dddd, D MMMM');
      this.formattedTime = moment(fixtureStartDate).format('h:mma');
    },
    checkRoundId() {
      this.roundId = this.$route.params.roundId
    },
    addRoundFixturesDefaultWinMargin(fixtures) {
      this.fixturesArray = [];
      fixtures.forEach((fixture) => {
        fixture.isSelected = undefined;
        fixture.WinningTeamId = null;
        fixture.teamNumber = null;
        this.fixturesArray.push(fixture);
      });
    },
    async submitPredictionsButtonClicked() {
      const fixture = {};
      await this.fixturesArray.asyncForEach(async (match) => {
        fixture.msisdn = this.user.msisdn;
        fixture.roundId = this.roundId;
        fixture.cricketFixtureId = match.CricketFixtureID;
        fixture.eventTypeId = 1;
        fixture.teamId = match.teamId || match.WinningTeamId;
        fixture.battingWinMargin = match.battingWinMargin || match.BattingWinMargin;
        fixture.bowlingWinMargin = match.bowlingWinMargin || match.BowlingWinMargin;
        if (fixture.teamId === undefined) {
          this.noTeamSelectedError = true
        } else if (fixture.battingWinMargin === undefined) {
          this.noTeamSelectedError = false;
          this.noWinMarginError = true
        } else {
          await PredictorService.enterCricketRoundPredictions(fixture);
          this.$router.push({name: 'MyRounds'});
        }
      });
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.checkIfRoundPreviouslyEntered();
    this.dateConversion(this.fixturesArray);
    this.checkRoundId();
    this.setIsLoading(false)
  }
}
</script>

<style scoped>
.body-container {
  background-color: #e8e8e8;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 50px;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.make-predictions-header-container {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  height: 45px;
}

.go-back-key {
  position: absolute;
  height: 20px;
  left: 27px;
  top: 13px;
}

.predict-scores-header {
  height: 45px;
  border-radius: 0 0 20px 20px;
  font-size: 14px;
  font-weight: 500;
}

.earn-points-button {
  font-size: 15px;
  background-color: #FFFFFF;
  border-radius: 8px;
  height: 30px;
  font-weight: 700;
}

.round-date-time {
  font-weight: 600;
  border-radius: 8px;
  height: 30px;
  font-size: 15px;
}

.make-prediction-team-margin-container {
  border-bottom: 1px solid gray;
}

.winner-margin-text {
  font-size: 14px;
  font-weight: 600;
}

.team-badge-container-default {
  border: 2px solid black;
  border-radius: 8px;
  height: 80px;
  width: 100px;
}

.team-badge-container-selected {
  border: 2px solid red;
  border-radius: 8px;
  height: 80px;
  width: 100px;
  background: #FFFFFF;
}


.submit-predictions-button {
  border-radius: 12px;
  height: 40px;
  font-size: 17px;
  border: 0;
}

.error-message-text {
  font-weight: 600;
}

.win-margin-container-default {
  position: relative;
  border: 2px solid black;
  border-radius: 8px;
  height: 80px;
}

.win-margin-container-selected {
  position: relative;
  border: 2px solid red;
  border-radius: 8px;
  height: 80px;
  color: red;
  background-color: #FFFFFF;
}

.win-margin-type-box-default {
  position: absolute;
  top: -10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 6px;
}

.win-margin-type-box-selected {
  position: absolute;
  top: -10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 6px;
  color: red;
}

.win-margin-wicket-run-text {
  font-weight: 600;
  font-size: 12px;
}

.vs-text {
  font-weight: 600;
}


.earn-points-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  border-radius: 15px;
}

.popup-header-breakdown-text {
  font-weight: 700;
}

table {
  width: 90%;
}

.table-header {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.point-description {
  font-weight: 400;
}

.exit-popup-img {
  position: absolute;
  right: -15px;
  top: -15px;
  width: 40px;
}

tr {
  border-bottom: .2px solid gray;
}

@media screen and (max-width: 392px) {
  .earn-points-button {
    font-size: 12px;
  }
}
</style>

<template>
  <div class="body-container" v-if="!this.isLoading">
    <div
        class="d-flex flex-column justify-content-center text-center primary-bg-colour primary-text make-predictions-header-container">
      <img @click="backButtonClicked" :src="require(`../brand/${brandConfig}/assets/left-keyboard-arrow.png`)"
           class="go-back-key">
      {{ formatString('league_bar_predict_text') }} {{ this.selectedSport }}
    </div>
    <div
        class="d-flex flex-column justify-content-center text-center p-4 secondary-bg-colour primary-text predict-scores-header">
      {{ formatString('make_predictions_header_text') }}
    </div>
    <div class="mt-2">
      <div class="d-flex flex-row justify-content-center col-12 text-center">
        <div @click="openCloseEarnPointsPopUp()"
             class="d-flex flex-row align-items-center justify-content-center earn-points-button">
          {{ formatString('make_predictions_earn_points_button') }}
        </div>
        <div @click="autoPredictButtonClicked(leagueFixturesArray)" class="d-flex flex-row align-items-center justify-content-center primary-bg-colour primary-text auto-predict-button">
          {{ formatString('make_predictions_soccer_auto_predict_button') }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center">
      <div
          class="d-flex flex-row justify-content-around round-date-time col-12 mt-2 p-1 secondary-bg-colour primary-text">
        <div>{{ this.formattedDate }}</div>
        <div>{{ this.formattedTime }}</div>
      </div>
      <div class="d-flex flex-column  pt-1 col-11" v-for="(fixture, index) in leagueFixturesArray" :key="index">
        <div class="match-badges-incrementer-container" v-if="checkMatchNotStarted(fixture)">
          <div class="d-flex flex-row pt-2 pb-2">
            <div v-if="selectedSportLeague !== 'chlg'" @click="teamInsightButtonClicked(fixture.Team1Id, fixture.Team2Id)" class="more-stats-icon secondary-text">
              <div class="text-more-stats-icon">i</div>
            </div>
            <div class="d-flex flex-column align-items-center col-5">
              <img class="badge-icon" :src="this.findImageModule(fixture.Team1Name)[0]">
              <div v-if="displayClubName" class="team-name">{{ this.findImageModule(fixture.Team1Name)[1] }}</div>
            </div>
            <div class="d-flex flex-row align-items-center col-7">
              <div @click="minusButtonClicked(index, 1 , fixture.FixtureStarted)"
                   class="col-3 text-center primary-text secondary-bg-colour minus-decrement">-
              </div>
              <div class="col-3 text-center score-value">{{ fixture.Team1Score }}</div>
              <div @click="plusButtonClicked(index, 1, fixture.FixtureStarted)"
                   class="col-3 text-center primary-text secondary-bg-colour plus-incrementer">+
              </div>
            </div>
          </div>
          <div class="d-flex flex-row pt-2 pb-3">
            <div class="d-flex flex-column align-items-center col-5">
              <img class="badge-icon" :src="this.findImageModule(fixture.Team2Name)[0]">
              <div v-if="displayClubName" class="team-name">{{ this.findImageModule(fixture.Team2Name)[1] }}</div>
            </div>
            <div class="d-flex flex-row align-items-center col-7">
              <div @click="minusButtonClicked(index, 2, fixture.FixtureStarted)"
                   class="col-3 text-center primary-text secondary-bg-colour minus-decrement">-
              </div>
              <div class="col-3 text-center score-value">{{ fixture.Team2Score }}</div>
              <div @click="plusButtonClicked(index, 2, fixture.FixtureStarted)"
                   class="col-3 text-center primary-text secondary-bg-colour plus-incrementer">+
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-center pt-3 col-8">
        <button id="submitButton" @click="submitPredictionsButtonClicked" :disabled="submitButtonDisabled"
                class="submit-predictions-button primary-bg-colour primary-text">
          {{ formatString('make_predictions_submit_button') }}
        </button>
      </div>
    </div>
    <div v-if="earnPointsPopup"
         class="d-flex flex-column align-items-center earn-points-popup col-10 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <img @click="openCloseEarnPointsPopUp()" class="exit-popup-img"
           :src="require(`../brand/${this.brandConfig}/assets/exit-icon.png`)">
      <div class="text-center col-10 pt-2 pb-2 popup-header-breakdown-text">
        {{ formatString('make_predictions_earn_points_popup_header_text') }}
      </div>
      <table class="table table-borderless">
        <thead class="table-header">
        <tr>
          <th scope="col">{{ formatString('make_predictions_earn_points_table_header_description') }}</th>
          <th class="d-flex flex-row justify-content-end" scope="col">
            {{ formatString('make_predictions_earn_points_table_header_points') }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th class="point-description" scope="row">{{ formatString('make_predictions_earn_points_table_row_home') }}
          </th>
          <td class="d-flex flex-row justify-content-end">{{ predictorScoreBreakdown[0].Value }}</td>
        </tr>
        <tr>
          <th class="point-description" scope="row">{{ formatString('make_predictions_earn_points_table_row_away') }}
          </th>
          <td class="d-flex flex-row justify-content-end">{{ predictorScoreBreakdown[1].Value }}</td>
        </tr>
        <tr>
          <th class="point-description" scope="row">
            {{ formatString('make_predictions_earn_points_table_row_result') }}
          </th>
          <td class="d-flex flex-row justify-content-end">{{ predictorScoreBreakdown[2].Value }}</td>
        </tr>
        <tr>
          <th class="point-description" scope="row">
            {{ formatString('make_predictions_earn_points_table_row_correct') }}
          </th>
          <td class="d-flex flex-row justify-content-end">{{ predictorScoreBreakdown[3].Value }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="teamStatsPopup"
         class="d-flex flex-column align-items-center justify-content-center team-stats-popup col-11 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <img @click="teamInsightButtonClicked()" class="col-2 close-popup-img" src="../assets/game/exit-icon.png">
      <div v-if="loadingSpinner === true" class="d-flex justify-content-center m-4">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="col-12" v-if="loadingSpinner === false">
        <div class="d-flex flex-row align-items-center text-center col-12 mt-4 mb-4">
          <div class="col-4 statistics-header">
            {{ formatString('make_predictions_soccer_team_statistics_popup_header') }}
          </div>
          <div class="col-4">
            <img class="badge-icon" :src="this.findImageModule(teamLeagueStatistics[0].Name)">
          </div>
          <div class="col-4">
            <img class="badge-icon" :src="this.findImageModule(teamLeagueStatistics[1].Name)">
          </div>
        </div>
        <div class="d-flex flex-row col-12">
          <div class="col-4 statistic-given-text">
            {{ formatString('make_predictions_soccer_team_statistics_popup_team_five_matches') }}
          </div>
          <div class="d-flex flex-row justify-content-center col-4 matches-streak-container">
            <div class="d-flex flex-row justify-content-center">
              <div :class="`${this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[0].LastResultOne)[1]} text-center col-2`">
                {{ this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[0].LastResultOne)[0] }}
              </div>
              <div :class="`${this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[0].LastResultTwo)[1]} text-center col-2`">
                {{ this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[0].LastResultTwo)[0] }}
              </div>
              <div :class="`${this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[0].LastResultThree)[1]} text-center col-2`">
                {{ this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[0].LastResultThree)[0] }}
              </div>
              <div :class="`${this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[0].LastResultFour)[1]} text-center col-2`">
                {{ this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[0].LastResultFour)[0] }}
              </div>
              <div :class="`${this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[0].LastResultFive)[1]} text-center col-2`">
                {{ this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[0].LastResultFive)[0] }}
              </div>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-center">
            <div :class="`${this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[1].LastResultOne)[1]} text-center col-2`">
              {{ this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[1].LastResultOne)[0] }}
            </div>
            <div :class="`${this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[1].LastResultTwo)[1]} text-center col-2`">
              {{ this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[1].LastResultTwo)[0] }}
            </div>
            <div :class="`${this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[1].LastResultThree)[1]} text-center col-2`">
              {{ this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[1].LastResultThree)[0] }}
            </div>
            <div :class="`${this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[1].LastResultFour)[1]} text-center col-2`">
              {{ this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[1].LastResultFour)[0] }}
            </div>
            <div :class="`${this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[1].LastResultFive)[1]} text-center col-2`">
              {{ this.displayMatchResultAbbreviationCircle(teamLeagueStatistics[1].LastResultFive)[0] }}
            </div>
          </div>
        </div>
        <div v-if="selectedSportLeague !== 'chlg'" class="d-flex flex-row align-items-center col-12 mt-2 mb-2">
          <div class="col-4 statistic-given-text">
            {{ formatString('make_predictions_soccer_team_statistics_popup_team_position') }}
          </div>
          <div class="col-4 position-text text-center">
            {{ teamLeagueStatistics[0].TeamRank }}
          </div>
          <div class="col-4 position-text text-center">
            {{ teamLeagueStatistics[1].TeamRank }}
          </div>
        </div>
        <div class="d-flex flex-row align-items-center col-12 mt-2 mb-4">
          <div class="col-4 statistic-given-text">
            {{ formatString('make_predictions_soccer_team_statistics_popup_team_results', [this.selectedSportLeague.toUpperCase()]) }}
          </div>
          <div class="d-flex flex-row justify-content-center col-4">
            <div class="green-circle text-center">
              {{ teamLeagueStatistics[0].Wins }}
            </div>
            <div class="grey-circle text-center">
              {{ teamLeagueStatistics[0].Ties }}
            </div>
            <div class="red-circle primary-bg-colour text-center">
              {{ teamLeagueStatistics[0].Losses }}
            </div>
          </div>
          <div class="d-flex flex-row justify-content-center col-4">
            <div class="green-circle text-center">
              {{ teamLeagueStatistics[1].Wins }}
            </div>
            <div class="grey-circle text-center">
              {{ teamLeagueStatistics[1].Ties }}
            </div>
            <div class="red-circle primary-bg-colour text-center">
              {{ teamLeagueStatistics[1].Losses }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {translationsFormatter} from "@/functions/translationsFormatter";
import PredictorService from "@/services/predictorService";
import moment from "moment";

export default {
  name: "MakePredictionsSoccer",
  data() {
    return {
      predictorScoreBreakdown: undefined,
      leagueFixturesArray: undefined,
      leagueTabsArray: undefined,
      displayClubName: false,
      earnPointsPopup: false,
      teamStatsPopup: false,
      submitButtonDisabled: false,
      teamLeagueStatistics: undefined,
      loadingSpinner: undefined,
    }
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'selectedSport', 'user', 'selectedSportsLeagues', 'selectedSportLeague'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setSelectedSport']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders);
    },
    async teamInsightButtonClicked(teamOneId, teamTwoId) {
      this.teamStatsPopup = this.teamStatsPopup === false;
      this.loadingSpinner = true
      await this.setTeamLeagueStatistics(teamOneId, teamTwoId);
      this.loadingSpinner = false
    },
    async setTeamLeagueStatistics(teamOneId, teamTwoId) {
      let homeTeamSoccerStatsResponse = await PredictorService.getTeamInsights(this.selectedSportLeague, teamOneId);
      let awayTeamSoccerStatsResponse = await PredictorService.getTeamInsights(this.selectedSportLeague, teamTwoId);
      this.teamLeagueStatistics = [homeTeamSoccerStatsResponse.data, awayTeamSoccerStatsResponse.data]
    },
    displayMatchResultAbbreviationCircle(matchResult) {
      if (matchResult === 'Tie') {
        return ['D', 'grey-circle']
      } else if (matchResult === 'n/a') {
        return ['?', 'grey-circle']
      } else if (matchResult === 'Win') {
        return ['W', 'green-circle']
      } else return ['L', 'red-circle']
    },
    autoPredictButtonClicked(fixtures) {
      fixtures.forEach((match) => {
        match.Team1Score = this.resultScoreRandomizer(0, 5);
        match.Team2Score = this.resultScoreRandomizer(0, 5);
      })
      this.scrollToSubmitButton()
    },
    scrollToSubmitButton() {
      const submitButtonId = document.getElementById("submitButton");
      submitButtonId.scrollIntoView({behavior: 'smooth', block: 'center'});
    },
    resultScoreRandomizer(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    checkMatchNotStarted(fixture) {
      return moment(fixture.StartDate).isAfter(new Date()) === true;
    },
    dateConversion(fixtures) {
      let fixtureStartDate = fixtures[0].StartDate
      this.formattedDate = moment(fixtureStartDate).format('dddd, D MMMM');
      this.formattedTime = moment(fixtureStartDate).format('h:mma');
    },
    backButtonClicked() {
      if (this.selectedSportsLeagues === undefined) {
        this.$router.push({name: 'SportSelection'});
      } else this.$router.push({name: 'LeagueSelection', params: {sportName: this.selectedSport.toLowerCase()}});
    },
    findImageModule(teamName) {
      let teamNameReformat = teamName.split(" ").join("-").toLowerCase()
      try {
        return [require(`@/assets/teamBadges/soccer/${teamNameReformat}-logo-icon.png`)]
      } catch (e) {
        return [require(`../assets/game/default-badge.png`), this.displayTeamName(teamName)]
      }
    },
    addRoundFixturesDefault(fixtures) {
      this.leagueFixturesArray = []
      fixtures.forEach((fixture) => {
        fixture.Team1Score = 0;
        fixture.Team2Score = 0;
        this.leagueFixturesArray.push(fixture);
      });
    },
    displayTeamName(teamName) {
      this.displayClubName = true
      return teamName
    },
    minusButtonClicked(index, teamNumber) {
      if (teamNumber === 1) {
        if (this.leagueFixturesArray[index].Team1Score > 0) --this.leagueFixturesArray[index].Team1Score
      } else if (this.leagueFixturesArray[index].Team2Score > 0) {
        --this.leagueFixturesArray[index].Team2Score
      }
    },
    plusButtonClicked(index, teamNumber) {
      teamNumber === 1 ? ++this.leagueFixturesArray[index].Team1Score : ++this.leagueFixturesArray[index].Team2Score;
    },
    async submitPredictionsButtonClicked() {
      this.submitButtonDisabled = true;
      const fixture = {};
      await this.leagueFixturesArray.asyncForEach(async (match) => {
        fixture.msisdn = this.user.msisdn;
        fixture.roundId = this.$route.params.roundId
        fixture.soccerFixtureId = match.FixtureID ? match.FixtureID : match.SoccerFixtureID;
        fixture.eventTypeId = 1;
        fixture.team1Id = match.Team1Id
        fixture.team2Id = match.Team2Id
        fixture.team1Score = match.Team1Score;
        fixture.team2Score = match.Team2Score;
        await PredictorService.enterSoccerRoundPredictions(fixture, this.user.msisdn);
      });
      this.$router.push({name: 'MyRounds'});
    },
    async setLeagueActiveTabs() {
      let getLeaguesResponse = await PredictorService.getLeagues(`${1}`)
      let leagueTabs = getLeaguesResponse.data
      this.leagueTabsArray = leagueTabs.filter(function (leagues) {
        return leagues.HasActiveRound === 1
      })
    },
    async checkIfRoundPreviouslyEntered() {
      this.leagueFixturesArray = [];
      let fixturesEnteredResponse = await PredictorService.getPlayerRoundPredictionsLive(`${this.user.msisdn}`, `${this.$route.params.roundId}`)
      if (fixturesEnteredResponse.data.roundPredictions.length > 0) {
        this.leagueFixturesArray = fixturesEnteredResponse.data.roundPredictions;
      } else {
        let leagueFixtures = await PredictorService.getLeagueFixtures(`${this.user.msisdn}`, `${this.$route.params.roundId}`);
        this.addRoundFixturesDefault(leagueFixtures.data.fixtures);
      }
      this.predictorScoreBreakdown = fixturesEnteredResponse.data.pointsScoringBreakdown;
      this.setSelectedSport('Soccer')
    },
    openCloseEarnPointsPopUp() {
      this.earnPointsPopup = this.earnPointsPopup === false;
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.setLeagueActiveTabs()
    await this.checkIfRoundPreviouslyEntered()
    this.dateConversion(this.leagueFixturesArray);
    this.setIsLoading(false);
  }
}
</script>

<style scoped>
.body-container {
  overflow-y: scroll;
  padding-bottom: 50px;
  overflow-x: hidden;
  background-color: #e8e8e8;
  background-size: 100%;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.make-predictions-header-container {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  height: 45px;
}

.go-back-key {
  position: absolute;
  height: 20px;
  left: 27px;
  top: 13px;
}

.predict-scores-header {
  height: 45px;
  border-radius: 0 0 20px 20px;
  font-size: 14px;
  font-weight: 500;
}

.round-date-time {
  font-weight: 600;
  border-radius: 8px;
  height: 30px;
}

.badge-icon {
  width: 40px;
}

.minus-decrement {
  height: 40px;
  font-weight: 700;
  border-radius: 14px 0px 0px 0px;
  font-size: 22px;
}

.score-value {
  font-weight: 700;
  font-size: 22px;
}

.plus-incrementer {
  height: 40px;
  font-weight: 700;
  border-radius: 0px 0px 14px 0px;
  font-size: 22px;
}

.match-badges-incrementer-container {
  border-bottom: .5px solid #a8a8a8;
  position: relative;
}

.more-stats-icon {
  position: absolute;
  right: -2px;
  top: 12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1.5px solid red;
}

.matches-streak-container {
  margin-left: 3px;
  margin-right: 3px;
}

.text-more-stats-icon {
  position: absolute;
  right: 7.5px;
  top: -2.5px;
  font-size: 15px;
  font-weight: 600;
}

.team-name {
  font-size: 10px;
}

.submit-predictions-button {
  border-radius: 12px;
  height: 40px;
  font-size: 17px;
  border: 0;
}

.league-tabs {
  height: 30px;
  padding-left: 10px;
}

.league-text-default {
  color: #a8a8a8;
  font-weight: 600;
}

.league-icon {
  height: 100%
}

.earn-points-button, .auto-predict-button {
  font-size: 15px;
  border-radius: 8px;
  height: 32px;
  font-weight: 700;
}

.earn-points-button {
  background-color: #FFFFFF;
  margin-right: 3px;
  width: 50%;
}

.auto-predict-button {
  margin-left: 3px;
  width: 50%;
}

.earn-points-popup, .team-stats-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.earn-points-popup {
  background-color: #FFFFFF;
  border-radius: 15px;
}

.team-stats-popup {
  background-color: #e8e8e8;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 4px;
}

.popup-header-breakdown-text {
  font-weight: 700;
}

table {
  width: 90%;
}

.table-header {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.point-description {
  font-weight: 400;
}

.exit-popup-img {
  position: absolute;
  right: -15px;
  top: -15px;
  width: 40px;
}

.statistics-header {
  font-size: 22px;
  font-weight: 600;
}

.position-text {
  font-weight: 600;
}

.green-circle, .grey-circle, .red-circle {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: inline-block;
  color: #FFFFFF;
  font-size: 10.5px;
  margin: 1.5px;
}

.green-circle {
  background-color: #00D30A;
}

.grey-circle {
  background-color: #777777;
}

.red-circle {
  background-color: #F42525;
}

.close-popup-img {
  position: fixed;
  right: -25px;
  top: -26px;
}

.statistic-given-text {
  padding-left: 6px;
  font-size: 13px;
}

@media screen and (max-width: 392px) {
  .earn-points-button, .auto-predict-button {
    font-size: 12px;
  }
}

</style>

<template>
  <div class="body-container">
    <div class="d-flex flex-column">
      <div
          class="d-flex flex-column justify-content-center text-center primary-bg-colour primary-text make-predictions-header-container">
        <img @click="backButtonClicked" :src="require(`../brand/${brandConfig}/assets/left-keyboard-arrow.png`)"
             class="go-back-key">
        {{ formatString('league_bar_predict_text') }} {{ this.selectedSport }}
      </div>
      <div
          class="d-flex flex-column justify-content-center text-center p-4 secondary-bg-colour primary-text predict-scores-header">
        {{ formatString('make_predictions_header_text') }}
      </div>
      <div class="mt-2">
        <div class="d-flex flex-row justify-content-center col-12 text-center">
          <div @click="openCloseEarnPointsPopUp()"
               class="d-flex flex-row align-items-center justify-content-center earn-points-button">
            {{ formatString('make_predictions_earn_points_button') }}
          </div>
          <div @click="autoPredictButtonClicked(fixturesArray)" class="d-flex flex-row align-items-center justify-content-center primary-bg-colour primary-text auto-predict-button">
            {{ formatString('make_predictions_soccer_auto_predict_button') }}
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center">
        <div
            class="d-flex flex-row justify-content-around round-date-time col-11 mt-3 p-1 secondary-bg-colour primary-text">
          <div>{{ formattedDate }}</div>
          <div>{{ formattedTime }}</div>
        </div>
        <div class="text-center col-11 pb-3"
             v-for="(fixture, index) in fixturesArray" :key="index">
          <div class="make-prediction-team-margin-container" v-if="checkMatchNotStarted(fixture)">
            <div class="pt-2 winner-margin-text">
              {{ formatString('make_predictions_win_margin_text') }}
            </div>
            <div class="d-flex flex-row justify-content-center team-select-container pt-1">
              <div @click="teamInsightButtonClicked(fixture.Team1Id, fixture.Team2Id)" class="more-stats-icon secondary-text">
                <div class="text-more-stats-icon">i</div>
              </div>
              <div @click="teamSelectedToWin(fixture, fixture.Team1Id, 1)"
                   :class=" fixture.teamNumber === 1 || fixture.WinningTeam === fixture.Team1Id ? 'd-flex flex-column justify-content-center align-items-center align-content-center team-badge-container-selected' : 'd-flex flex-column justify-content-center align-items-center align-content-center team-badge-container-default'">
                <img class="col-8 team-badge-img" :src="this.findImageModule(fixture.Team1Name)[0]"/>
                <div v-if="displayClubName" class="team-name">{{ this.findImageModule(fixture.Team1Name)[1] }}</div>
              </div>
              <div @click="resultDrawSelected(fixture)"
                   class="d-flex flex-column justify-content-center draw-container p-2 mt-1">
                <div
                    :class="fixture.teamNumber === null || fixture.WinningTeam === null  ? 'd-flex flex-column justify-content-center align-content-center align-items-center secondary-text draw-box-selected' : 'd-flex flex-column justify-content-center align-content-center align-items-center draw-box-default'">
                  {{ formatString('make_predictions_draw_text') }}
                </div>
              </div>
              <div @click="teamSelectedToWin(fixture, fixture.Team2Id, 2)"
                   :class=" fixture.teamNumber === 2 || fixture.WinningTeam === fixture.Team2Id  ?'d-flex flex-column justify-content-center align-items-center align-content-center team-badge-container-selected' : 'd-flex flex-column justify-content-center align-items-center align-content-center team-badge-container-default'">
                <img class="col-8 team-badge-img" :src="this.findImageModule(fixture.Team2Name)[0]"/>
                <div v-if="displayClubName" class="team-name">{{ this.findImageModule(fixture.Team2Name)[1] }}</div>
              </div>
            </div>
            <div v-if="fixture.Draw || fixture.WinMargin" class="d-flex flex-row justify-content-center pt-2">
              <button @click="editPredictionButtonClicked(fixture)"
                      class="secondary-bg-colour primary-text edit-prediction-button">
                {{ formatString('make_predictions_edit_predictions_text') }}
              </button>
            </div>
            <div class="d-flex flex-row justify-content-center pt-2">
              <div
                  class="d-flex flex-column justify-content-center win-margin-container secondary-bg-colour primary-text"
                  v-if="fixture.Draw === false || fixture.Draw === null">
                {{ fixture.WinMargin }}
              </div>
            </div>
            <div v-if="fixture.isSelected && autoPredictSelected === false" class="d-flex flex-row align-items-center justify-content-center pt-2">
              <img class="col-1 m-2" src="../assets/game/left-arrow-icon.png">
              <div class="scrollable-container col-8">
                <div @click="setWinMargin(fixture, option)" class="option mb-3" v-for="option in options">
                  {{ option }}
                </div>
              </div>
              <img class="col-1 m-2" src="../assets/game/right-arrow-icon.png">
            </div>
          </div>
        </div>
        <div class="d-flex flex-column justify-content-center pt-3 col-8">
          <button id="submitButton" @click="submitPredictionsButtonClicked" :disabled="submitButtonDisabled"
                  class="submit-predictions-button primary-bg-colour primary-text">
            {{ formatString('make_predictions_submit_button') }}
          </button>
        </div>
        <div class="secondary-text error-message-text pt-2" v-if="noTeamSelectedError">
          {{ formatString('make_predictions_no_team_selected') }}
        </div>
      </div>
      <div v-if="rugbyInsightsPopup">
        <RugbyTeamInsights :teamInsights="teamLeagueStatistics"/>
      </div>
      <div v-if="earnPointsPopup"
           class="d-flex flex-column align-items-center earn-points-popup col-10 col-sm-8 col-md-6 col-lg-5 col-xl-3">
        <img @click="openCloseEarnPointsPopUp()" class="exit-popup-img"
             :src="require(`../brand/${this.brandConfig}/assets/exit-icon.png`)">
        <div class="text-center col-10 pt-2 pb-2 popup-header-breakdown-text">
          {{ formatString('make_predictions_earn_points_popup_header_text') }}
        </div>
        <table class="table table-borderless">
          <thead class="table-header">
          <tr>
            <th scope="col">{{ formatString('make_predictions_earn_points_table_header_description') }}</th>
            <th class="d-flex flex-row justify-content-end" scope="col">
              {{ formatString('make_predictions_earn_points_table_header_points') }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th class="point-description" scope="row">
              {{ formatString('make_predictions_earn_points_table_row_correct_team') }}
            </th>
            <td class="d-flex flex-row justify-content-end">{{ pointsScoringBreakdown.CorrectTeam }}</td>
          </tr>
          <tr>
            <th class="point-description" scope="row">
              {{ formatString('make_predictions_earn_points_table_row_win_margin') }}
            </th>
            <td class="d-flex flex-row justify-content-end">{{ pointsScoringBreakdown.InWinMargin }}</td>
          </tr>
          <tr>
            <th class="point-description" scope="row">
              {{ formatString('make_predictions_earn_points_table_row_exact_margin') }}
            </th>
            <td class="d-flex flex-row justify-content-end">{{ pointsScoringBreakdown.ExactMargin }}</td>
          </tr>
          <tr>
            <th class="point-description" scope="row">
              {{ formatString('make_predictions_earn_points_table_row_draw') }}
            </th>
            <td class="d-flex flex-row justify-content-end">{{ pointsScoringBreakdown.Draw }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {translationsFormatter} from "@/functions/translationsFormatter";
import PredictorService from "@/services/predictorService";
import moment from "moment";
import RugbyTeamInsights from "@/components/RugbyTeamInsights";

export default {
  name: "MakePredictionsRugby",
  data() {
    return {
      fixturesArray: undefined,
      formattedTime: undefined,
      formattedDate: undefined,
      pointsScoringBreakdown: undefined,
      earnPointsPopup: false,
      noTeamSelectedError: false,
      submitButtonDisabled: false,
      displayClubName: false,
      teamLeagueStatistics: undefined,
      loadingSpinner: undefined,
      autoPredictSelected: undefined,
    }
  },
  components: {RugbyTeamInsights},
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'selectedSport', 'user', 'selectedSportsLeagues', 'rugbyInsightsPopup', 'selectedSportLeague'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setSelectedSport', 'setRugbyInsightsPopup']),
    autoPredictButtonClicked(fixtures) {
      fixtures.forEach((match) => {
        this.setWinMargin(match, this.randomizeNumber(1, 30))
        this.teamSelectedToWin(match, this.setWinningTeamId(match))
      })
      this.autoPredictSelected = true
    },
    setWinningTeamId(match) {
      const teamIdOptions = [match.Team1Id, match.Team2Id];
      const randomIndex = Math.floor(Math.random() * teamIdOptions.length);
      return teamIdOptions[randomIndex];
    },
    randomizeNumber(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders);
    },
    async teamInsightButtonClicked(teamOneId, teamTwoId) {
      await this.setTeamLeagueStatistics(teamOneId, teamTwoId);
      this.setRugbyInsightsPopup(true)
    },
    async setTeamLeagueStatistics(teamOneId, teamTwoId) {
      let homeTeamInsightsResponse = await PredictorService.getTeamInsights(this.selectedSportLeague, teamOneId);
      let awayTeamInsightsResponse = await PredictorService.getTeamInsights(this.selectedSportLeague, teamTwoId);
      this.teamLeagueStatistics = [homeTeamInsightsResponse.data, awayTeamInsightsResponse.data]
    },
    scrollToSubmitButton() {
      const submitButtonId = document.getElementById("submitButton");
      submitButtonId.scrollIntoView({behavior: 'smooth', block: 'center'});
    },
    checkMatchNotStarted(fixture) {
      return moment(fixture.StartDate).isAfter(new Date()) === true;
    },
    findImageModule(teamName) {
      const teamNameReformat = teamName.split(" ").join("-").toLowerCase()
      try {
        return [require(`@/assets/teamBadges/rugby/${teamNameReformat}-logo-icon.png`)]
      } catch (e) {
        return [require(`../assets/game/default-badge.png`), this.displayTeamName(teamName)]
      }
    },
    displayTeamName(teamName) {
      this.displayClubName = true
      return teamName
    },
    dateConversion(fixtures) {
      let fixtureStartDate = fixtures[0].StartDate
      this.formattedDate = moment(fixtureStartDate).format('dddd, D MMMM');
      this.formattedTime = moment(fixtureStartDate).format('h:mma');
    },
    backButtonClicked() {
      if (this.selectedSportsLeagues === undefined) {
        this.$router.push({name: 'SportSelection'});
      } else this.$router.push({name: 'LeagueSelection', params: {sportName: this.selectedSport.toLowerCase()}});
    },
    openCloseEarnPointsPopUp() {
      this.earnPointsPopup = this.earnPointsPopup === false;
    },
    setWinMargin(fixture, winMargin) {
      fixture.WinMargin = winMargin
      fixture.isSelected = true
      fixture.Draw = false;
      fixture.isSelected = false;
    },
    teamSelectedToWin(fixture, teamId, teamNumber) {
      this.autoPredictSelected = false
      fixture.teamNumber = teamNumber
      fixture.isSelected = true;
      fixture.teamId = teamId;
      fixture.draw = 0;
      fixture.WinningTeam = teamId;
      this.noTeamSelectedError = false;
      fixture.winMarginText = true;
    },
    resultDrawSelected(fixture) {
      fixture.teamNumber = null;
      fixture.draw = 1;
      fixture.teamId = null;
      fixture.WinMargin = null;
      fixture.isSelected = false;
      fixture.WinningTeam = null;
      this.noTeamSelectedError = false;
    },
    addRoundFixturesDefaultWinMargin(fixtures) {
      this.fixturesArray = [];
      fixtures.forEach((fixture) => {
        fixture.WinMargin = null;
        fixture.isSelected = undefined;
        fixture.teamNumber = undefined;
        fixture.draw = null;
        this.fixturesArray.push(fixture);
      });
    },
    editPredictionButtonClicked(fixture) {
      fixture.Draw = undefined;
      fixture.Points = 0;
      fixture.isSelected = null;
      fixture.teamNumber = undefined;
      fixture.teamId = null;
      fixture.WinMargin = null;
      fixture.WinningTeam = undefined;
    },
    async submitPredictionsButtonClicked() {
      this.submitButtonDisabled = true;
      const fixture = {};
      await this.fixturesArray.asyncForEach(async (match) => {
        this.checkForWinMargin(match);
        this.checkForNoSelection(match)
        fixture.msisdn = this.user.msisdn;
        fixture.roundId = this.roundId;
        fixture.rugbyFixtureId = match.FixtureID || match.RugbyFixtureID;
        fixture.winMargin = match.WinMargin;
        fixture.teamId = match.teamId;
        fixture.draw = match.draw;

        await PredictorService.enterRugbyRoundPredictions(fixture, this.user.msisdn);
        this.$router.push({name: 'MyRounds'});
      });
    },
    checkForWinMargin(match) {
      if (match.teamId && match.WinMargin === null) {
        match.WinMargin = 1;
      }
    },
    checkForNoSelection(match) {
      if (!match.teamId && !match.WinMargin) {
        match.draw = 1;
      }
    },
    setWinningTeamIdAndWinMargin() {
      this.fixturesArray.forEach((fixture) => {
        fixture.teamId = fixture.WinningTeam
      })
    },
    async checkIfRoundPreviouslyEntered() {
      this.fixturesArray = [];
      this.leagueFixtures = await PredictorService.getLeagueFixtures(`${this.user.msisdn}`, `${this.$route.params.roundId}`);
      let userFixturesEnteredResponse = await PredictorService.getPlayerRoundPredictionsLive(`${this.user.msisdn}`, `${this.$route.params.roundId}`)

      if (userFixturesEnteredResponse.data.roundPredictions.length > 0) {
        this.fixturesArray = userFixturesEnteredResponse.data.roundPredictions;
        this.setWinningTeamIdAndWinMargin();
      } else {
        this.addRoundFixturesDefaultWinMargin(this.leagueFixtures.data.fixtures);
      }
      this.pointsScoringBreakdown = this.leagueFixtures.data.pointsScoringBreakdown[0];
      this.setSelectedSport('Rugby')
    },
    checkRoundId() {
      this.roundId = this.$route.params.roundId
    },
    scrollerSetMargin() {
      this.options = [];
      for (let i = 1; i < 121; i++) {
        this.options.push(i);
      }
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.checkIfRoundPreviouslyEntered();
    this.dateConversion(this.fixturesArray);
    this.checkRoundId();
    this.scrollerSetMargin()
    this.setIsLoading(false)
  },
}
</script>

<style scoped>
.body-container {
  overflow-y: scroll;
  padding-bottom: 50px;
  overflow-x: hidden;
  background-color: #e8e8e8;
  background-size: 100%;
}

.scrollable-container {
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
}

.earn-points-button {
  background-color: #FFFFFF;
  margin-right: 3px;
  width: 50%;
  border: .2px solid black;
}


.earn-points-button, .auto-predict-button {
  font-size: 15px;
  border-radius: 8px;
  height: 32px;
  font-weight: 700;
}

.scrollable-container::-webkit-scrollbar {
  display: none;
}

.team-name {
  font-size: 10px;
}

.option {
  background-color: #2D343B;
  width: 50px !important;
  height: 40px;
  border-radius: 6px;
  margin-right: 0.6rem;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.8rem;
  font-size: 1.8rem;
}

.team-select-container {
  position: relative;
}

.edit-prediction-button {
  border-radius: 8px;
  border: 0;
  font-size: 14px;
}

.win-margin-container {
  height: 50px;
  width: 50px;
  color: white;
  font-size: 1.8rem;
  border-radius: 10px;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.make-predictions-header-container {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  height: 45px;
}

.go-back-key {
  position: absolute;
  height: 20px;
  left: 27px;
  top: 13px;
}

.predict-scores-header {
  height: 45px;
  border-radius: 0 0 20px 20px;
  font-size: 14px;
  font-weight: 500;
}

.earn-points-button {
  font-size: 15px;
  background-color: #FFFFFF;
  border-radius: 8px;
  font-weight: 700;
}

.round-date-time {
  font-weight: 600;
  border-radius: 8px;
  height: 30px;
  font-size: 15px;
}

.make-prediction-team-margin-container {
  border-bottom: 1px solid gray;
}

.winner-margin-text {
  font-size: 14px;
  font-weight: 600;
}

.team-badge-container-default {
  border: 2px solid black;
  border-radius: 8px;
  height: 80px;
  width: 80px;
}

.team-badge-container-selected {
  border: 2px solid red;
  border-radius: 8px;
  height: 80px;
  width: 80px;
  background: #FFFFFF;
}

.draw-container {
  height: 70px;
}

.draw-box-selected {
  border: 2px solid red;
  border-radius: 8px;
  height: 50px;
  width: 50px;
  font-size: 14px;
  font-weight: 600;
  background: #FFFFFF;
}

.draw-box-default {
  border: 2px solid black;
  border-radius: 8px;
  height: 50px;
  width: 50px;
  font-size: 14px;
  font-weight: 600;
}

.win-margin-box {
  border-radius: 2px;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
}


.win-margin-box input {
  border-radius: 2px;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
}

::placeholder {
  color: white;
}

.earn-points-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  border-radius: 15px;
  border: .2px solid black;
}

.popup-header-breakdown-text {
  font-weight: 700;
}

table {
  width: 90%;
}

.table-header {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.point-description {
  font-weight: 400;
}

.exit-popup-img {
  position: absolute;
  right: -15px;
  top: -15px;
  width: 40px;
}

tr {
  border-bottom: .2px solid gray;
}

.submit-predictions-button {
  border-radius: 12px;
  height: 40px;
  font-size: 17px;
  border: 0;
}

.error-message-text {
  font-weight: 600;
}

.carousel__item {
  height: 40px;
  width: 100%;
  background-color: #3a3a3a;
  color: white;
  font-size: 16px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 2px;
}

.auto-predict-button {
  margin-left: 3px;
  width: 50%;
}

.more-stats-icon {
  position: absolute;
  right: 0px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1.5px solid red;
}

.text-more-stats-icon {
  position: absolute;
  right: 7.5px;
  top: -2.5px;
  font-size: 15px;
  font-weight: 600;
}

@media screen and (max-width: 392px) {
  .earn-points-button, .auto-predict-button {
    font-size: 12px;
  }
}
</style>

<template>
  <div class="body-container" v-if="!this.isLoading">
    <div class="d-flex flex-column justify-content-center text-center primary-bg-colour primary-text league-selection-header-container">
      <img @click="backButtonClicked" :src="require(`../brand/${brandConfig}/assets/left-keyboard-arrow.png`)" class="go-back-key">
      {{ formatString('league_select_predict_text') }} {{ this.selectedSport }}
    </div>
    <div class="d-flex flex-column justify-content-center text-center p-4 secondary-bg-colour primary-text select-league-container">
      {{ formatString('league_select_text') }}
    </div>
    <div class="d-flex flex-row justify-content-start">
      <div class="flex-column align-items-center text-center pt-1 p-1 select-sport-text" v-for="(sport) in activeSports" :key="sport.id">
        <div :class="sportSelected(sport) ? 'sport-name-highlighted primary-bg-colour  primary-text col-2' : 'sport-name-default col-2'" @click="changeSport(sport)">
          {{ sport[0].SportName }}
        </div>
      </div>
    </div>
    <LeagueBar v-for="(activeLeague, index) in this.selectedSportsLeagues" :key="index" :activeLeague="activeLeague"/>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {translationsFormatter} from "@/functions/translationsFormatter";
import LeagueBar from "@/components/LeagueBar";
import PredictorService from "@/services/predictorService";
import {environmentConfig} from "@/config/enviromentConfig";

export default {
  name: "LeagueSelection",
  components: {LeagueBar},
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'activeSports', 'selectedSport', 'selectedSportsLeagues'])
  },
  data() {
    return {
      allSports: [],
    }
  },
  async beforeMount() {
    this.setIsLoading(true)
    this.setIsLoading(false)
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setSelectedSport', 'setSportsLeagues']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders);
    },
    changeSport(sport) {
      this.setSelectedSport(sport[0].SportName);
      this.setSportsLeagues(sport);
      this.$router.push({name: 'LeagueSelection', params: {sportName: sport[0].SportName.toLowerCase()}})
    },
    sportSelected(sport) {
      if (this.selectedSport === sport[0].SportName) {
        return true
      }
    },
    backButtonClicked() {
      this.$router.push('/sport-selection');
    },
  }
}
</script>

<style scoped>
.body-container {
  overflow-y: scroll;
  padding-bottom: 50px;
  overflow-x: hidden;
  background-color: #e8e8e8;
  background-size: 100%;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.league-selection-header-container {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  height: 45px;
}

.go-back-key {
  position: absolute;
  height: 20px;
  left: 27px;
  top: 13px;
}

.select-league-container {
  height: 45px;
  border-radius: 0 0 20px 20px;
  font-size: 14px;
  font-weight: 500;
}

.select-sport-text {
  font-weight: 600;
}

.sport-name-highlighted {
  width: 100%;
  border-radius: 15px;
  padding: 4px;
  font-size: 14px;
}

.sport-name-default {
  margin-top: 2px;
  color: #a8a8a8;
  font-size: 14px;
}


</style>

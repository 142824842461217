<template>
  <div class="body-container" v-if="!this.isLoading">
    <div class="d-flex flex-column justify-content-start pt-2">
      <div class="leaderboard-header-text">
        {{ formatString('leaderboard_page_header') }}
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center pt-1 winnings-container">
      <img :src="fetchBanner()" class="main-banner">
      <div class="text-center play-circle">
        <div @click="playCircleClicked()" class="play-circle-text">
          {{ formatString('leaderboard_page_play_circle_text') }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-start pt-2">
      <div class="flex-column align-items-center text-center pt-1 p-1 select-sport-text" v-for="(sport) in sportsTabs"
           :key="sport.id">
        <div
            :class="sportSelected(sport) ? 'sport-name-highlighted primary-bg-colour  primary-text col-2' : 'sport-name-default col-2'"
            @click="changeSport(sport)">
          {{ sport.SportName }}
        </div>
      </div>
    </div>
    <router-view>
      <div class="d-flex flex-column align-items-center text-center" v-if="noResultsMessageError">
        <img class="col-6" src="../assets/game/flag-icon.png">
        {{ formatString('my_results_no_rounds_entered_text') }}
      </div>
      <div class="table-container" v-if="!noResultsMessageError">
        <table class="table-borderless table-responsive">
          <thead>
          <tr>
            <th scope="col">{{ formatString('user_winnings_table_header_one') }}</th>
            <th class="text-center" scope="col">{{ formatString('user_winnings_table_header_two') }}</th>
            <th class="text-center" scope="col">{{ formatString('user_winnings_table_header_three') }}</th>
            <th class="text-center" scope="col">{{ formatString('user_winnings_table_header_four') }}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="round in this.userResults" :key="round.id" @click="leagueRoundClicked(round)"
              :class="round.PrizeName !== '-' ?'primary-bg-colour primary-text table-row-won' : 'table-row'">
            <td>
              <div class="d-flex flex-row align-items-center">
                <img class="league-icon-img" :src="this.findLeagueImage(round.LeagueAbbreviation)">
                <div class="round-number">{{ moment(round.EndDate).format('Do MMMM YYYY') }}</div>
              </div>
            </td>
            <td class="text-center position-text">{{ round.Position }}</td>
            <td class="text-center points-text">{{ round.Points }}</td>
            <td class="text-center">
              <div>
                <div v-if="round.PrizeValue === 10"><img class="data-won-icon-img" :src="this.findDataWonLogo(round)">
                </div>
                <div v-if="round.PrizeName === '-'">-</div>
              </div>
            </td>
            <td class="text-center">
              <div v-if="round.PrizeValue === 10"><img class="arrow-img-won" src="../assets/game/right-arrow-white.png">
              </div>
              <div v-if="round.PrizeName === '-'"><img class="arrow-img-default" src="../assets/game/right-arrow-grey.png">
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </router-view>
  </div>
</template>

<script>
import PredictorService from "@/services/predictorService";
import {mapMutations, mapState} from "vuex";
import {translationsFormatter} from "@/functions/translationsFormatter";
import moment from "moment";

export default {
  name: "UserWinnings",
  data() {
    return {
      userResults: [],
      noResultsMessageError: false,
    }
  },
  created: function () {
    this.moment = moment;
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'userLandingStats', 'selectedSport', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setSelectedSport', 'setSportId']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders);
    },
    fetchBanner() {
      return require(`../brand/${this.brandConfig}/assets/results-main-banner.png`)
    },
    playCircleClicked() {
      this.$router.push('/sport-selection');
    },
    changeSport(sport) {
      this.setIsLoading(true);
      this.setSelectedSport(sport.SportName)
      if (sport.SportName === 'Rugby') {
        this.userResults = this.rugbyResults;
      } else if (sport.SportName === 'Cricket') {
        this.userResults = this.cricketResults;
      } else this.userResults = this.soccerResults;
      this.checkNoResults();
      this.setIsLoading(false);
    },
    checkNoResults() {
      this.noResultsMessageError = this.userResults === undefined;
    },
    async getUserLeaderboardResults() {
      let leaderboardResults = await PredictorService.getPlayerResults(`${this.user.msisdn}`);
      if (leaderboardResults.data.length > 0) {
        this.userLeaderboardArray = leaderboardResults.data
        this.userLeaderboardArray.reverse()
      } else {
        this.noResultsMessageError = true;
      }
    },
    generateSportsTabs() {
      this.setSelectedSport('Soccer');
      let sportsTabsResponse = PredictorService.getAllSports();
      this.sportsTabs = sportsTabsResponse.data;
    },
    sportSelected(sport) {
      if (this.selectedSport === sport.SportName) {
        return true
      }
    },
    findLeagueImage(leagueName) {
      let leagueNameReformat = leagueName.split(" ").join("-").toLowerCase()
      try {
        return [require(`../assets/leagueLogos/${leagueNameReformat}-logo-colour.png`)]
      } catch (e) {
        return [require(`../assets/game/default-badge.png`)]
      }
    },
    findDataWonLogo(round) {
      let prizeNameReformat = round.PrizeName.split(" ").join("-").toLowerCase()
      try {
        return [require(`../brand/${this.brandConfig}/assets/${prizeNameReformat}-icon.png`)]
      } catch (e) {
        return [require(`../brand/${this.brandConfig}/assets/250-mb-icon.png`)]
      }
    },
    leagueRoundClicked(leagueRound) {
      this.$router.push({name: 'RoundLeaderboard', params: {roundId: leagueRound.RoundId}})
    },
    setDefaultSport() {
      if (this.selectedSport === undefined) {
        this.setSelectedSport('Soccer');
        this.setSportId(1);
      }
    },
    sortUserRounds() {
      if (this.userLeaderboardArray !== undefined) {
        this.filteredLeaderboard = this.userLeaderboardArray.filter(sport => !sport.Promo && sport.Free === null || !sport.Promo && !sport.Free);

        this.userResults = this.filteredLeaderboard.reduce((roundsResults, item) => {
          const round = (roundsResults[item.SportId] || []);
          round.push(item);
          roundsResults[item.SportId] = round;
          return roundsResults;
        }, {});
      }
      this.setSportResults()
    },
    setSportResults() {
      this.soccerResults = this.userResults[1]
      this.cricketResults = this.userResults[3]
      this.rugbyResults = this.userResults[250]
      this.userResults = this.soccerResults;
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    this.setDefaultSport();
    await this.getUserLeaderboardResults();
    this.sortUserRounds();
    this.generateSportsTabs();
    this.setIsLoading(false);
  },
}
</script>

<style scoped>
.body-container {
  overflow-y: scroll;
  padding-bottom: 50px;
  overflow-x: hidden;
  background-color: #e8e8e8;
  background-size: 100%;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.leaderboard-header-text {
  font-weight: 700;
}

.winnings-container {
  position: relative;
}

.main-banner {
  width: 100%;
}

.play-circle {
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #05a105;
  color: white;
}

.play-circle-text {
  font-weight: 700;
  font-size: 34px;
  margin-top: 25%;
}

.my-winnings-circle {
  position: absolute;
  z-index: 1;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #FFFFFF;
  border: solid 4px;
  margin-top: 23px;
}

.my-winnings-circle-data-amount-text {
  margin-top: 5px;
  font-size: 24px;
  font-weight: 700;
}

.my-winnings-circle-data-label {
  margin-top: -10px;
  font-weight: 750;
  font-size: 10px;
}

.you-won-text {
  position: absolute;
  z-index: 1;
  color: white;
  padding-bottom: 84px;
  font-size: 28px;
  font-weight: 700;
  text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
}

.sport-name-highlighted {
  width: 100%;
  border-radius: 15px;
  padding: 4px;
  font-size: 14px;
  font-weight: 600;
}

.sport-name-default {
  margin-top: 2px;
  color: #a8a8a8;
  font-size: 14px;
  font-weight: 600;
}

table {
  border-collapse: separate;
  border-spacing: 0 4px;
  width: 100%;
}

.table-row {
  background-color: #ffffff;
  height: 40px;
}

.table-row-won {
  height: 40px;
}

thead {
  font-size: 10px;
  color: #a8a8a8;
}

td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

td:first-child {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.league-icon-img {
  width: 24px;
}

.round-number {
  font-size: 10px;
  margin-left: 6px;
}

.position-text, .points-text, .round-number {
  font-weight: 600;
}

.data-won-icon-img {
  width: 50px;
}

.arrow-img-won {
  height: 20px;
}

.arrow-img-default {
  width: 23px;
}

@media screen and (max-width: 365px) {
  .play-circle {
    width: 90px;
    height: 90px;
  }
}


</style>

<template>
  <div class="change-username-page" v-if="!this.isLoading">
    <div class="d-flex flex-column justify-content-center text-center primary-bg-colour primary-text change-username-header">
      <img @click="backButtonClicked" :src="require(`../brand/${brandConfig}/assets/left-keyboard-arrow.png`)" class="go-back-key">
      {{ formatString('username_header_text') }}
    </div>
    <div class="d-flex flex-column justify-content-center text-center p-4 secondary-bg-colour primary-text change-username-explanation">
      {{ formatString('username_explanation_content_text') }}
    </div>
    <div class="text-center pt-5 pb-4 username-input-container">
      <img :src="require(`../brand/${brandConfig}/assets/user-icon.png`)" class="mb-5 user-profile-icon">
      <div class="d-flex flex-row justify-content-center pt-3 current-username-text">
        <div>{{ formatString('username_current_username_text') }}</div>
        <div class="username">{{ this.user.username }}</div>
      </div>
      <input class="col-11 text-center new-username-input-box" type="text" v-model="newUsername" :placeholder="formatString('username_input_placeholder_text')">
      <div v-if="invalidUsername" class="p-3 secondary-text username-min-max-character-explanation-text">
        {{ formatString('username_min_max_character_text') }}
      </div>
      <button @click="changeUsernameButtonClicked(newUsername)" class="mt-4 col-9 primary-bg-colour primary-text change-username-button">{{
          formatString('username_change_username_button_text')
        }}
      </button>
      <button @click="backButtonClicked" class="mt-1 mb-2 col-4 secondary-bg-colour primary-text back-button">{{ formatString('username_back_button_text') }}</button>
    </div>
  </div>
</template>
<script>
import {translationsFormatter} from "@/functions/translationsFormatter";
import {mapState, mapMutations} from "vuex";
import UpstreamService from "@/services/upstreamService";
import {environmentConfig} from "@/config/enviromentConfig";

export default {
  name: "EditUsername",
  data() {
    return {
      newUsername: '',
      invalidUsername: false,
    }
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setUser']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders);
    },
    async changeUsernameButtonClicked(username) {
      this.setIsLoading(true)
      let usernameCharacterCountCheckResponse = this.checkUsernameCharacterCount(username);

      if (usernameCharacterCountCheckResponse === true) {
        const validateUsernameResponse = await UpstreamService.validateUsernameAgainstDB(username, this.token);
        await this.validateUsername(validateUsernameResponse, username)
      }
      this.setIsLoading(false)
    },
    checkUsernameCharacterCount(username) {
      if (username.length < 6 || username.length > 14) {
        this.invalidUsername = true;
        return false;
      } else return true;
    },
    async validateUsername(validateUsernameResponse, username) {
      if (validateUsernameResponse.data.isValid === false) {
        this.invalidUsername = true
      } else if (validateUsernameResponse.data.isValid) {
        this.invalidUsername = false;
        this.newUsername = username;
        await UpstreamService.updateUserData(this.user.msisdn, username);
        const userData = await UpstreamService.getUserStatus(`${this.user.token}`);
        this.setUser(userData.data)
        this.$router.push('/settings')
      }
    },
    backButtonClicked() {
      this.$router.push('/settings');
    },
  }
}
</script>

<style scoped>
.change-username-page {
  background-color: #e8e8e8;
}

.change-username-header {
  position: relative;
  font-weight: 600;
  font-size: 18px;
  height: 45px;
}

.go-back-key {
  position: absolute;
  height: 20px;
  left: 10px;
  top: 13px;
}

.change-username-explanation {
  height: 70px;
  border-radius: 0 0 20px 20px;
  font-size: 14px;
  font-weight: 500;
}

.username-input-container {
  position: relative;
  margin-top: -12px;
}

.user-profile-icon {
  height: 120px;
}

.username {
  font-weight: 700;
}

.new-username-input-box {
  height: 50px;
  border-radius: 10px;
  border: 1px solid white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 2;
}

::placeholder {
  font-size: 14px;
  color: #cccccc;
}

.username-min-max-character-explanation-text {
  font-weight: 700;
  font-size: 14px;
}

.change-username-button {
  height: 45px;
  border: 0;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
}

.back-button {
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  border: 0;
}
</style>

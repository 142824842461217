import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from "../views/LandingPage";
import SportSelection from "@/views/SportSelection";
import LeagueSelection from "@/views/LeagueSelection";
import MakePredictionsSoccer from "@/views/MakePredictionsSoccer";
import MakePredictionsRugby from "@/views/MakePredictionsRugby";
import MakePredictionsCricket from "@/views/MakePredictionsCricket";
import MyRounds from "@/views/MyRounds";
import MyResults from "@/views/MyResults";
import UserWinnings from "@/views/UserWinnings";
import RoundLeaderboard from "@/views/RoundLeaderboard";
import UserSettings from "@/views/UserSettings";
import EditUsername from "@/views/EditUsername";
import ManageNotifications from "@/views/ManageNotifications";
import CancelPackage from "@/views/CancelPackage";
import HowToPlay from "@/views/HowToPlay";
import PrizesList from "@/views/PrizesList";
import {userAuthenticate} from "@/functions/userAuthenticate";

const routes = [
  {
    path: '/',
    redirect: 'profile'
  },
  {
    path: '/profile',
    name: 'LandingPage',
    component: LandingPage,
  },
  {
    path: '/sport-selection',
    name: 'SportSelection',
    component: SportSelection,
  },
  {
    path: '/league-selection/:sportName',
    name: 'LeagueSelection',
    component: LeagueSelection,
  },
  {
    path: '/make-predictions/soccer/:roundId',
    name: 'MakePredictionsSoccer',
    component: MakePredictionsSoccer,
  },
  {
    path: '/make-predictions/rugby/:roundId',
    name: 'MakePredictionsRugby',
    component: MakePredictionsRugby,
  },
  {
    path: '/make-predictions/cricket/:roundId',
    name: 'MakePredictionsCricket',
    component: MakePredictionsCricket,
  },
  {
    path: '/my-rounds',
    name: 'MyRounds',
    component: MyRounds,
  },
  {
    path: '/my-results/:sportId',
    name: 'MyResults',
    component: MyResults,
  },
  {
    path: '/user-winnings',
    name: 'UserWinnings',
    component: UserWinnings,
  },
  {
    path: '/round-leaderboard/:roundId',
    name: 'RoundLeaderboard',
    component: RoundLeaderboard,
  },
  {
    path: '/settings',
    name: 'UserSettings',
    component: UserSettings,
    children: [
      {
        path: 'username',
        name: 'EditUsername',
        component: EditUsername,
      },
      {
        path: 'notifications',
        name: 'ManageNotifications',
        component: ManageNotifications,
      },
      {
        path: 'unsubscribe',
        name: 'CancelPackage',
        component: CancelPackage,
      },
    ]
  },
  {
    path: '/how-to-play',
    name: 'HowToPlay',
    component: HowToPlay,
  },
  {
    path: '/prizes',
    name: 'PrizesList',
    component: PrizesList,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  await userAuthenticate(to, from, next)
})


export default router

<template>
  <div class="d-flex flex-column body-container" v-if="!this.isLoading">
    <div class="pt-2 pb-2 how-to-play-header-text">
      {{ formatString('how_to_play_header') }}
    </div>
    <div class="d-flex flex-column align-items-center">
      <div class="d-flex flex-column col-11">

        <div @click="generalHeaderClicked" class="d-flex flex-row how-to-play-topics">
          <div class="col-10 topic-header-text">{{ formatString('how_to_play_general_header_text') }}</div>
          <div v-if="!generalInfo" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">+</div>
          <div v-if="generalInfo" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">-</div>
        </div>
        <div v-if="generalInfo" class="pt-2 how-to-play-info-box">
          <div class="p-2">1. {{ formatString('how_to_play_general_info_point_one') }}</div>
          <div class="p-2">2. {{ formatString('how_to_play_general_info_point_two') }}</div>
        </div>


        <div @click="editLineupHeaderClicked" class="d-flex flex-row mt-2 how-to-play-topics">
          <div class="col-10 topic-header-text">{{ formatString('how_to_play_edit_lineup_header_text') }}</div>
          <div v-if="!editLineupRules" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">+</div>
          <div v-if="editLineupRules" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">-</div>
        </div>
        <div v-if="editLineupRules" class="pt-2 how-to-play-info-box">
          <div class="p-2">{{ formatString('how_to_play_edit_lineup_info') }}</div>
        </div>

        <div @click="entryFeesHeaderClicked" class="d-flex flex-row mt-2 how-to-play-topics">
          <div class="col-10 topic-header-text">{{ formatString('how_to_play_entry_fees_header_text') }}</div>
          <div v-if="!entryFeeRules" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">+</div>
          <div v-if="entryFeeRules" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">-</div>
        </div>
        <div v-if="entryFeeRules" class="pt-2 how-to-play-info-box">
          <div class="p-2">{{ formatString('how_to_play_entry_fees_info') }}</div>
        </div>

        <div @click="entryCancellationHeaderClicked" class="d-flex flex-row mt-2 how-to-play-topics">
          <div class="col-10 topic-header-text">{{ formatString('how_to_play_entry_cancellation_header_text') }}</div>
          <div v-if="!entryCancellationRules" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">+</div>
          <div v-if="entryCancellationRules" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">-</div>
        </div>
        <div v-if="entryCancellationRules" class="pt-2 how-to-play-info-box">
          <div class="p-2">{{ formatString('how_to_play_entry_cancellation_info') }}</div>
        </div>

        <div @click="cancelContestsHeaderClicked" class="d-flex flex-row mt-2 how-to-play-topics">
          <div class="col-10 topic-header-text">{{ formatString('how_to_play_cancel_contests_header_text') }}</div>
          <div v-if="!cancelContestsRules" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">+</div>
          <div v-if="cancelContestsRules" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">-</div>
        </div>
        <div v-if="cancelContestsRules" class="pt-2 how-to-play-info-box">
          <div class="p-2">{{ formatString('how_to_play_cancel_contests_info') }}</div>
        </div>

        <div @click="contestTermHeaderClicked" class="d-flex flex-row mt-2 how-to-play-topics">
          <div class="col-10 topic-header-text">{{ formatString('how_to_play_contest_term_header_text') }}</div>
          <div v-if="!contestTermRules" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">+</div>
          <div v-if="contestTermRules" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">-</div>
        </div>
        <div v-if="contestTermRules" class="pt-2 how-to-play-info-box">
          <div class="p-2">{{ formatString('how_to_play_contest_term_info') }}</div>
        </div>


        <div @click="gameCancelHeaderClicked" class="d-flex flex-row mt-2 how-to-play-topics">
          <div class="col-10 topic-header-text">{{ formatString('how_to_play_game_cancel_header_text') }}</div>
          <div v-if="!gameCancel" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">+</div>
          <div v-if="gameCancel" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">-</div>
        </div>
        <div v-if="gameCancel" class="pt-2 how-to-play-info-box">
          <div class="p-2">{{ formatString('how_to_play_game_cancel_info') }}</div>
        </div>

        <div @click="prizesHeaderClicked" class="d-flex flex-row mt-2 how-to-play-topics">
          <div class="col-10 topic-header-text">{{ formatString('how_to_play_prizes_header_text') }}</div>
          <div v-if="!prizesRules" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">+</div>
          <div v-if="prizesRules" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">-</div>
        </div>
        <div v-if="prizesRules" class="pt-2 how-to-play-info-box">
          <div class="p-2">1. {{ formatString('how_to_play_contest_prizes_point_one') }}</div>
          <div class="p-2">2. {{ formatString('how_to_play_contest_prizes_point_two') }}</div>
          <div class="p-2">3. {{ formatString('how_to_play_contest_prizes_point_three') }}</div>
          <div class="p-2">4. {{ formatString('how_to_play_contest_prizes_point_four') }}</div>
          <div class="p-2">5. {{ formatString('how_to_play_contest_prizes_point_five') }}</div>
          <div class="p-2">6. {{ formatString('how_to_play_contest_prizes_point_six') }}</div>
          <div class="p-2">7. {{ formatString('how_to_play_contest_prizes_point_seven') }}</div>
          <div class="p-2">8. {{ formatString('how_to_play_contest_prizes_point_eight') }}</div>
          <div class="p-2">9. {{ formatString('how_to_play_contest_prizes_point_nine') }}</div>
          <div class="p-2">10. {{ formatString('how_to_play_contest_prizes_point_ten') }}</div>
          <div class="p-2">11. {{ formatString('how_to_play_contest_prizes_point_eleven') }}</div>
          <div class="p-2">12. {{ formatString('how_to_play_contest_prizes_point_twelve') }}</div>
          <div class="p-2">13. {{ formatString('how_to_play_contest_prizes_point_thirteen_point_header') }}
            <div class="p-2">
              1. {{ formatString('how_to_play_contest_prizes_point_thirteen_point_one') }}
            </div>
            <div class="p-2">
              2. {{ formatString('how_to_play_contest_prizes_point_thirteen_point_two') }}
            </div>
          </div>
        </div>

        <div @click="notificationsHeaderClicked" class="d-flex flex-row mt-2 how-to-play-topics">
          <div class="col-10 topic-header-text">{{ formatString('how_to_play_notifications_header_text') }}</div>
          <div v-if="!notificationsInfo" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">+</div>
          <div v-if="notificationsInfo" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">-</div>
        </div>
        <div v-if="notificationsInfo" class="pt-2 how-to-play-info-box">
          <div class="p-2">{{ formatString('how_to_play_notifications_info') }}</div>
        </div>

        <div @click="pointScoringHeaderClicked" class="d-flex flex-row mt-2 how-to-play-topics">
          <div class="col-10 topic-header-text">{{ formatString('how_to_play_point_scoring_header_text') }}</div>
          <div v-if="!pointScoringInfo" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">+</div>
          <div v-if="pointScoringInfo" class="d-flex flex-row justify-content-center col-2 secondary-text plus-icon">-</div>
        </div>
        <div v-if="pointScoringInfo" class="pt-2 how-to-play-info-box">
          <div class="p-2 scoring-system-header">{{ formatString('how_to_play_point_scoring_system_header_text') }}</div>
          <table class="table table-borderless">
            <thead class="table-header">
            <tr>
              <th scope="col">{{ formatString('make_predictions_earn_points_table_header_description') }}</th>
              <th class="d-flex flex-row justify-content-end" scope="col">{{ formatString('make_predictions_earn_points_table_header_points') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th class="point-description" scope="row">{{ formatString('make_predictions_earn_points_table_row_home') }}</th>
              <td class="d-flex flex-row justify-content-end">6</td>
            </tr>
            <tr>
              <th class="point-description" scope="row">{{ formatString('make_predictions_earn_points_table_row_away') }}</th>
              <td class="d-flex flex-row justify-content-end">6</td>
            </tr>
            <tr>
              <th class="point-description" scope="row">{{ formatString('make_predictions_earn_points_table_row_result') }}</th>
              <td class="d-flex flex-row justify-content-end">10</td>
            </tr>
            <tr>
              <th class="point-description" scope="row">{{ formatString('make_predictions_earn_points_table_row_correct') }}</th>
              <td class="d-flex flex-row justify-content-end">10</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {translationsFormatter} from "@/functions/translationsFormatter";
import {mapMutations, mapState} from "vuex";

export default {
  name: "HowToPlay",
  data() {
    return {
      generalInfo: false,
      contestRules: false,
      editLineupRules: false,
      entryFeeRules: false,
      entryCancellationRules: false,
      cancelContestsRules: false,
      contestTermRules: false,
      squadsRules: false,
      gameCancel: false,
      prizesRules: false,
      notificationsInfo: false,
      pointScoringInfo: false,
    }
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders);
    },
    generalHeaderClicked() {
      this.generalInfo = !this.generalInfo;
    },
    contestRulesHeaderClicked() {
      this.contestRules = !this.contestRules;
    },
    editLineupHeaderClicked() {
      this.editLineupRules = !this.editLineupRules;
    },
    entryFeesHeaderClicked() {
      this.entryFeeRules = !this.entryFeeRules;
    },
    entryCancellationHeaderClicked() {
      this.entryCancellationRules = !this.entryCancellationRules;
    },
    cancelContestsHeaderClicked() {
      this.cancelContestsRules = !this.cancelContestsRules;
    },
    contestTermHeaderClicked() {
      this.contestTermRules = !this.contestTermRules;
    },
    squadsHeaderClicked() {
      this.squadsRules = !this.squadsRules;
    },
    gameCancelHeaderClicked() {
      this.gameCancel = !this.gameCancel;
    },
    prizesHeaderClicked() {
      this.prizesRules = !this.prizesRules;
    },
    notificationsHeaderClicked() {
      this.notificationsInfo = !this.notificationsInfo;
    },
    pointScoringHeaderClicked() {
      this.pointScoringInfo = !this.pointScoringInfo;
    },
  },
}
</script>

<style scoped>

.body-container {
  background-color: #e8e8e8;
  background-size: 100%;
  overflow-y: scroll;
  padding-bottom: 50px;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.how-to-play-header-text {
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
}

.how-to-play-topics {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  height: 45px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
}

.topic-header-text {
  padding-left: 22px;
}

.plus-icon {
  font-weight: 600;
  font-size: 30px;
  padding-bottom: 5px;
}

.how-to-play-info-box {
  background-color: #FFFFFF;
  margin-top: -10px;
  border-radius: 0 0 10px 10px;
  padding-left: 15px;
  padding-right: 5px;
  font-size: 12px;
  font-weight: 700;
}

.scoring-system-header {
  text-decoration: underline;
}

</style>

import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class UpstreamService {
    static async trackLogin(msisdn) {
        return await axios.post(`${environmentConfig.upstreamService}/track-login`, {
            msisdn: msisdn,
            revenuePartnerId: 1,
            isFantasy : 0,
            isScorePredictor : 1,
            isWeb : 1
        })
    }

    static async headerEnrichedLogin(encryptedMsisdn) {
        const response = await axios({
            method: 'post',
            url: `${environmentConfig.upstreamService}/user-authentication/encrypted-msisdn-login`,
            data: {
                encryptedMsisdn,
            }
        });
        return response.data.token;
    }

    static async getUser(token) {
        return await axios.post(`${environmentConfig.upstreamService}/get-user-status`, {
            token
        })
    }

    static async unsubscribe(msisdn, sessionId, revenuePartnerId) {
        return await axios.post(`${environmentConfig.upstreamService}/v2/subscription`,
            {
                msisdn: msisdn,
                revenuePartnerId: `${revenuePartnerId}`,
                action: 'Cancellation',
                sessionId: sessionId,
            });
    }

    static async updateUserData(msisdn, newUsername, serviceMessages) {
        return axios.get(`${environmentConfig.upstreamService}/update-user-profile/?msisdn=${msisdn}&username=${newUsername}&optInServiceMsgs=${serviceMessages}`)
    }

    static async getUserStatus(token) {
        const user = await UpstreamService.invokeGetUserStatus(token);
        if (user.data.token === 'invalidToken') {
            localStorage.removeItem("token");
            if (store.state.brandConfig.brand === 'vc-za-en') window.open(`${environmentConfig.entryPortalHost}`, '_self');
        }
        return user
    }

    static async validateUsernameAgainstDB(username, userToken) {
        return axios.post(`${environmentConfig.upstreamService}/user-authentication/validate-username`, {
            username: username,
            userToken: userToken
        })
    }

    static async invokeGetUserStatus(token) {
        return await axios.post(`${environmentConfig.upstreamService}/get-user-status`, {
            token
        })
    }
}

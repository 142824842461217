<template>
  <div class="d-flex flex-column align-items-center">
    <div @click="publishedRoundBarClicked(this.leagueRound.SportId, this.leagueRound.SportName)" class="d-flex flex-row justify-content-center published-round-bar-container pt-1 pb-3">
      <div class="d-flex flex-column text-center justify-content-center win-data primary-bg-colour col-2">
        <img class="win-data-img" :src="require(`../brand/${this.brandConfig}/assets/win-data-amount-icon.png`)">
      </div>
      <div class="d-flex flex-row align-items-center league-title secondary-bg-colour primary-text col-8">
        <img class="league-icon-img"  :src="require(`../assets/game/${this.leagueRound.SportName.toLowerCase()}-icon.png`)">
        <div class="league-text m-1 col-10">{{ this.leagueRound.SportName }}</div>
      </div>
      <div class="view-button primary-text col-2">
        {{formatString('published_rounds_bar_view_button_text')}}
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {translationsFormatter} from "@/functions/translationsFormatter";
import PredictorService from "@/services/predictorService";

export default {
  name: "PublishedRoundBar",
  inheritAttrs: false,
  props: ['leagueRound'],
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['brandConfig', 'user']),
  },
  methods: {
    ...mapMutations(['setSelectedSport', 'setSportId']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders)
    },
    publishedRoundBarClicked(sportId, sportName){
      this.setSportId(sportId);
      this.setSelectedSport(sportName);
      this.$router.push({ name: 'MyResults', params: { sportId: sportId}});
    },
  }
}
</script>

<style scoped>
.published-round-bar-container {
  display: flex;
  justify-content: center;
  width: 108%;
  position: relative;
}

.games-to-edit-container {
  height: 16px;
  border-radius: 10px 10px 0 0;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: -23px;
}

.win-data {
  position: relative;
  height: 55px;
  border-radius: 25%;
  z-index: 2;
}

.win-data-img {
  margin-bottom: 5px;
  left: 3px;
  position: absolute;
  width: 100%;
}

.league-title {
  position: relative;
  height: 55px;
  margin-left: -20px;
  border-radius: 0 15px 15px 0;
  z-index: 1;
}

.league-text {
  padding-left: 30%;
  font-weight: 700;
  font-size: 12.5px;
}

.view-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  writing-mode: vertical-lr;
  margin-left: -20px;
  z-index: 0;
  border-radius: 15px 0 0 15px;
  -webkit-transform: rotate(-180deg);
  background-color: #019601;
}

.league-icon-img {
  position: absolute;
  width: 13%;
  left: 13%;
}
</style>

export const translations = {
    "network_title": 'MTN',
    "footer_profile_icon_text": "Profile",
    "footer_leaderboard_icon_text": "Leaderboard",
    "footer_play_icon_text": "Play",
    "footer_my_rounds_icon_text": "My Rounds",
    "footer_trophy_icon_text": "Results",
    "profile_header_text": "My Profile",
    "profile_airtime_won_text": "AIRTIME",
    "profile_play_text": "PLAY",
    "profile_prizes_won_label": 'PRIZES WON',
    "profile_total_season_points_header": "TOTAL SEASON POINTS",
    "profile_rounds_entered_header": "ROUNDS ENTERED",
    "profile_top_predictors_header": "TOP PREDICTORS",
    "profile_top_hundred_header": "TOP 100 FINISHES",
    "profile_how_to_play_button_text": "How To Play",
    "sidebar_menu_how_to_play_icon_text": "how to play",
    "sidebar_menu_trophy_icon_text": "prizes",
    "sidebar_menu_terms_conditions_icon_text": "t's & c's",
    "sidebar_menu_more_games_title": "More Games",
    "sidebar_menu_rugby_icon_text" : "fantasy rugby",
    "sidebar_menu_football_icon_text": "fantasy football",
    "sidebar_menu_free_play_icon_text": "freeplay predictor",
    "round_selection_header_text": "Gameplay Today: {0}",
    "round_selection_select_sport_text": 'Select a Sport',
    "sport_bar_games_to_play_count_text": "Leagues to play: {0}",
    "sport_bar_play_button_text" : 'Play',
    "league_select_text": 'Select a League',
    "league_select_predict_text": 'Predict',
    "league_bar_games_to_play_count_text": 'Games to Predict: {0}',
    "league_bar_predict_text": 'Predict',
    "make_predictions_header_text": 'Predict scores',
    "make_predictions_submit_button": 'Submit Predictions',
    "make_predictions_earn_points_button_text": 'How do I earn points?',
    "make_predictions_earn_points_popup_header_text": 'Take a look at the breakdown below of how to earn points:',
    "make_predictions_earn_points_table_header_description": 'Description',
    "make_predictions_earn_points_table_header_points": 'Points',
    "make_predictions_earn_points_table_row_home": 'Home score',
    "make_predictions_earn_points_table_row_away": 'Away score',
    "make_predictions_earn_points_table_row_result": 'Result',
    "make_predictions_earn_points_table_row_correct": 'All correct',
    "make_predictions_earn_points_table_row_correct_team": 'Correct Team',
    "make_predictions_earn_points_table_row_win_margin": 'Win Margin (within 5)',
    "make_predictions_earn_points_table_row_exact_margin": 'Exact Margin',
    "make_predictions_earn_points_table_row_draw": 'Draw',
    "make_predictions_no_team_selected": 'Error: No selections made',
    "my_rounds_primary_header_text": 'My Rounds',
    "my_rounds_secondary_header_text": 'Edit Predictions',
    "my_rounds_published_rounds_header_text": 'Published Rounds:',
    "entered_rounds_bar_edit_text": "Edit",
    "entered_rounds_bar_games_edit_text": 'Games to Edit: {0}',
    "my_results_primary_header_text": 'Predict {0}',
    "my_results_secondary_header_text": 'Results',
    "my_results_round_header": 'Round',
    "my_results_table_header_teams": 'Teams',
    "my_results_table_header_result": 'Game Result',
    "my_results_table_header_prediction": 'Your Prediction',
    "my_results_table_header_points": 'Your Points',
    "published_rounds_bar_view_button_text": 'View',
    "leaderboard_page_header": 'Leaderboard',
    "leaderboard_page_play_circle_text": 'PLAY',
    'leaderboard_page_you_won_text': 'You Won',
    'leaderboard_my_position_text': 'MY POSITION',
    'user_winnings_table_header_one' : 'Round',
    'user_winnings_table_header_two' : 'Position',
    'user_winnings_table_header_three' : 'Points',
    'user_winnings_table_header_four' : 'Prize Won',
    "round_leaderboard_table_header_one" : 'Place',
    "round_leaderboard_table_header_two" : 'Player',
    "round_leaderboard_table_header_three" : 'Points',
    "round_leaderboard_table_header_four" : 'Data Won',
    "round_leaderboard_data_amount" : '250MB',
    "round_leaderboard_popup_header_text" : 'Player Points Summary',
    "round_leaderboard_popup_point_one" : 'Game',
    "round_leaderboard_popup_point_two" : 'Predicted',
    "round_leaderboard_popup_point_three" : 'Points',
    "settings_header_text": 'Settings',
    "settings_change_username_button_text": 'Change Username',
    "settings_change_notifications_button_text": 'Notifications',
    "settings_change_cancel_package_button_text": 'Cancel Package',
    "unsubscribe_header_text": 'Cancel Package',
    "unsubscribe_status_text": 'You are currently subscribed to MTN Fantasy League',
    "unsubscribe_confirm_unsubscription_text": ' I confirm that I would like to cancel my package with MTN Fantasy League',
    "unsubscribe_confirm_button_text": 'Confirm',
    "unsubscribe_back_button_text" : 'Back',
    "username_header_text": 'Change Your Username',
    "username_explanation_content_text": 'Please enter a new username how you would like to be identified on our leaderboards',
    "username_current_username_text": 'Current Username:',
    "username_input_placeholder_text": 'Enter New Username',
    "username_min_max_character_text" : 'Username must be a mixture of alphabetical characters ranging from 6-14 characters',
    "username_change_username_button_text": 'Change Username',
    "username_back_button_text" : 'Back',
    "notifications_header_text": 'Notifications',
    "notifications_manage_text": 'Manage your notifications',
    "notifications_checkbox_text": 'Service messages',
    "notifications_service_updates_message": 'I would like to receive updates about our service or any promotions we run',
    "notifications_save_button_text": 'Save',
    "notifications_back_button_text" : 'Back',
    "how_to_play_header": 'How To Play:',
    "how_to_play_general_header_text": 'General',
    "how_to_play_general_info_point_one": 'Fanclash Contests is a game of skill and require some skill and knowledge of professional sports information as well as the Fanclash rules from You to accumulate predictor points;',
    "how_to_play_general_info_point_two": 'Predictor points are accumulated through the performance of real-life teams across multiple sporting disciplines and leagues;',
    "how_to_play_edit_lineup_header_text": 'Editing a Round Prediction',
    "how_to_play_edit_lineup_info": 'You can edit your Round Predictions at any point up to the start of a Contest by clicking on the edit option on the entry view. HOWEVER, once a Contest starts, your predictions will no longer be editable.',
    "how_to_play_entry_fees_header_text": 'Entry Fees',
    "how_to_play_entry_fees_info": 'There are no entry fees for the specific contests. Members of the Fanclash service will have the ability to enter into weekly and daily contests as long as their subscription to the content is active.',
    "how_to_play_entry_cancellation_header_text": 'Entry Cancellation',
    "how_to_play_entry_cancellation_info": 'Fanclash reserves the right to cancel any Game before it is scheduled to begin. When multiple Games with the same entry terms (such as but not limited to real–life games, anticipated pool size etc) are subject to cancellation due to the number of Contestants participating being too low, Fanclash may avoid cancellation by combining the player pools for those Games. HOWEVER Fanclash will never merge player pools with different entry terms;',
    "how_to_play_cancel_contests_header_text": 'Cancelling Contests',
    "how_to_play_cancel_contests_info": 'Fanclash reserves the right to cancel Contests at its discretion, without any restrictions. This would typically only be done where we believe there would be a widespread impact on the integrity of Contests due to problems on the site or real-life events impacting on the Contests.',
    "how_to_play_contest_term_header_text": 'Contest Term',
    "how_to_play_game_cancel_header_text": 'Cancellations & Postponements',
    "how_to_play_game_cancel_info": 'In the event that a scheduled real-life game is cancelled, moved to an earlier date (“pre-empted”), or postponed for any reason, no points will be awarded for players in the cancelled, pre-empted, or postponed game or event.',
    "how_to_play_prizes_header_text": 'Prizes',
    "how_to_play_contest_prizes_point_one": 'Fanclash offers many different types of Contests. For each one, Fanclash announces the prizes on offer at the start of the contest;',
    "how_to_play_contest_prizes_point_two": 'Prizes are not subject to change based on the number of Contestants or total entry fees for that Contest;',
    "how_to_play_contest_prizes_point_three": 'Prizes are awarded to Contestants who accumulate the most predictor points as detailed in the Contest details, after the particular Contest has ended. Fanclash will never determine winners based on the score, point-spread, or performance of any real-life team or combination of teams;',
    "how_to_play_contest_prizes_point_four": 'When a Contestant wins a Contest, the Prize will be awarded directly to the customer in the form of data. With prizes other than data will be contacted in person in accordance with the terms and condition attached to that specific prize.',
    "how_to_play_contest_prizes_point_five": 'Fanclash may offer “free” game play for entertainment purposes, without the possibilities of a Prize or Prizes being awarded. This will be clearly indicated at the outset;',
    "how_to_play_contest_prizes_point_six": 'Prize calculations are based on Contests’ results as at the time when Fanclash charts final point scoring.',
    "how_to_play_contest_prizes_point_seven": 'Points scored are based on the statistics from the data feed provided by our third party statistics provider (“Statistics Provider”). Although Fanclash uses its best endeavours to ensure a good reputable Service, it is solely reliant on the information provided by the Statistics Provider and is in no way responsible for faulty data received from the Statistics Provider.',
    "how_to_play_contest_prizes_point_eight": 'You hereby indemnify Fanclash against any and all claims, arising directly or indirectly due to faulty data received by Fanclash from its Statistics Provider;',
    "how_to_play_contest_prizes_point_nine": 'Fanclash waits for a certain period to elapse, to confirm the winner(s) of a Contest after the final game in the Contest is completed so that the Statistics Provider can correct any major errors in its system first;',
    "how_to_play_contest_prizes_point_ten": 'Note that once Prizes are awarded to the winners, scoring results will not be changed regardless of any official adjustments made by professional sports leagues.',
    "how_to_play_contest_prizes_point_eleven": 'This is not the same situation as a case where a correction needs to be made after settlement, due to a problem with the settlement process. In these cases, the settlement process (including prize awarding or data provisioning) may be reversed and games resettled correctly.',
    "how_to_play_contest_prizes_point_twelve": 'Prizes may not be substituted or transferred. All taxes associated with the receipt or use of any Prize is the sole responsibility of the winner. If any legal authority Contests Prize awards, Fanclash may, in its sole discretion, determine whether or not to award such Prizes.',
    "how_to_play_contest_prizes_point_thirteen_point_header": 'Ties',
    "how_to_play_contest_prizes_point_thirteen_point_one": 'If there is a tie/draw in a specific Contest among Fanclash users, the Prize will be divided equally between all tied players;',
    "how_to_play_contest_prizes_point_thirteen_point_two": 'In the event of a tie between players where the Prize(s) cannot be equally split the entry with the highest individually scoring athlete will be used as a tiebreaker, proceeding to the second-highest, third-highest, etc. player as needed to break the tie. In the event the tie remains unbroken, the player who submitted their team first will be declared the winner.',
    "how_to_play_notifications_header_text": 'Notifications',
    "how_to_play_notifications_info": 'Winners are generally advised by SMS within one business day. You hereby agree to Your Username and country location being published in the event that You are a winner. Winners may be asked to return via e-mail or regular mail an affidavit of eligibility and appropriate tax forms. A winner’s failure to comply with such requests can result in disqualification. Any Prize notification returned as undeliverable may result in disqualification and selection of an alternate winner. A list of winners for each competition period may be obtained by written request to: Fanclash: hello@thefanclash.com',
    "how_to_play_point_scoring_header_text": 'Point Scoring',
    "how_to_play_point_scoring_system_header_text": 'Predictor Scoring System',
    "how_to_play_contest_term_info": 'Contests will take place on a single day, multiple days or longer as specified in each Contest’s rules. Winners are determined by the criteria stated in the game rules.',
    "prizes_header_text": 'Prizes:',
    "prizes_promo_info_text": 'Stand a chance to WIN DATA and other GREAT PRIZES when you play MTN Score Predictor League!',
    "prizes_first_place_header_text": '1st - 20th Prize',
    "prizes_first_place_airtime_amount": '250MB Data',
    'round_selection_entered_all_rounds_text' : 'You have entered all upcoming rounds. To view your active predictions go to',
    'round_selection_entered_all_rounds_text_bold' : 'My Rounds',
    'my_rounds_no_predictions_text_bold' : 'Play',
    'my_rounds_no_predictions_text' : 'You haven\'t made any predictions yet. To make predictions go to',
    'my_results_no_rounds_entered_text': 'No historic round results',
    'make_predictions_win_margin_text': 'Select a winner and input a win margin',
    'make_predictions_draw_text': 'draw',
    'make_predictions_win_margin_between_text' : 'Win margin (between 1 and 120)',
    "round_leaderboard_entries_total_text": 'Number of Entries: {0}',
    "make_predictions_win_type_text_cricket": 'Select a winner and type of win',
    "make_predictions_earn_points_table_row_win_margin_two" : 'Win Margin',
    "make_predictions_cricket_win_by_text" : "WIN by:",
    "make_predictions_cricket_win_by_type_narrow" : "Narrow",
    "make_predictions_cricket_win_by_type_comfortable" : "Comfortable",
    "make_predictions_cricket_win_by_type_thrashing" : "Thrashing",
    "make_predictions_cricket_win_by_type_narrow_wickets" : "0-2 Wickets or",
    "make_predictions_cricket_win_by_type_narrow_runs" : "0-10 Runs",
    "make_predictions_cricket_win_by_type_comfortable_wickets" : "3-5 Wickets or",
    "make_predictions_cricket_win_by_type_comfortable_runs" : "11-25 Runs",
    "make_predictions_cricket_win_by_type_thrashing_wickets" : "6+ Wickets or",
    "make_predictions_cricket_win_by_type_thrashing_runs" : "26+ Runs",
    "my_results_no_result_text" : "No Result",
    "make_predictions_edit_predictions_text": 'Edit Predictions',
    "round_leaderboard_table_player_username_default" : 'Player',
    'sidebar_menu_terms_soccer_news_text': 'soccer news',
}


<template>
  <div class="body-container" v-if="!this.isLoading">
    <div class="d-flex flex-column justify-content-start pt-2">
      <div class="leaderboard-header-text">
        {{ formatString('leaderboard_page_header') }}
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center pt-1 user-place-container">
      <img :src="fetchBanner()" class="main-banner">
      <div class="text-center position-circle primary-border-colour secondary-text">
        <div class="d-flex flex-column align-items-center">
          <img class="badge-icon" :src="require(`../brand/${brandConfig}/assets/badge-icon.png`)">
          <div class="my-position-text"> {{ formatString('leaderboard_my_position_text') }}</div>
          <div class="d-flex flex-row user-position-number">
            <div>{{ this.getCorrectPositionAndOrdinalSuffix(`${this.currentUser.Position}`) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-1">
      <table>
        <thead class="text-center table-header">
        <tr>
          <th scope="col">{{ formatString('round_leaderboard_table_header_one') }}</th>
          <th scope="col">{{ formatString('round_leaderboard_table_header_two') }}</th>
          <th scope="col">{{ formatString('round_leaderboard_table_header_three') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="user in this.cleanedLeaderboard" :key="user.id" @click="checkUserPredictions(user)">
          <td class="text-center">
            {{ user.Position }}
          </td>
          <td class="d-flex flex-row align-items-center text-center table-row">
            <div class="d-flex flex-row justify-content-end col-2">
              <img class="user-icon" :src="require(`../brand/${brandConfig}/assets/user-icon.png`)">
            </div>
            <div v-if="user.Username !== 'null'" class="col-7 username-text">
              {{ user.Username }}
            </div>
            <div v-if="user.Username === 'null'" class="col-7 username-text">
              {{ formatString('round_leaderboard_table_player_username_default') }} {{ user.MSISDN.slice((-3)) }}
            </div>
          </td>
          <td class="text-center">
            {{ user.Points }}
          </td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex flex-column secondary-bg-colour primary-text col-9 user-predictions-popup"
           v-if="userPredictionsPopup">
        <img @click="openCloseUserPointsResultsPopUp()" class="exit-popup-img"
             :src="require(`../brand/${this.brandConfig}/assets/exit-icon.png`)">
        <div class="popup-header-text">
          {{ formatString('round_leaderboard_popup_header_text') }}
        </div>
        <div class="d-flex flex-column align-items-center">
          <table class="table-borderless primary-text popup-table m-3">
            <thead class="table-head-popup">
            <tr>
              <th scope="col">{{ formatString('round_leaderboard_popup_point_one') }}</th>
              <th class="text-center" scope="col">{{ formatString('round_leaderboard_popup_point_two') }}</th>
              <th class="text-center" scope="col">{{ formatString('round_leaderboard_popup_point_three') }}</th>
            </tr>
            </thead>
            <tbody class="table-body-popup">
            <tr class="popup-table-row" v-for="result in this.userPredictionsAndPoints" :key="result.id">
              <td>{{ result.Team1Name }} vs {{ result.Team2Name }}</td>
              <td v-if="this.userPredictionsAndPoints[0].SportId === 1" class="text-center">
                {{ result.Team1Score }}-{{ result.Team2Score }}
              </td>
              <td v-if="this.userPredictionsAndPoints[0].SportId === 3" class="text-center">
                {{ result.PredictedWinningTeam }}
              </td>
              <td v-if="this.userPredictionsAndPoints[0].SportId === 250" class="text-center">
                <div v-if="result.WinMargin != null">{{ result.WinMargin }}
                  ({{ shortenRugbyTeamNames(result.UsersPickWinningTeamName) }})
                </div>
                <div v-if="result.WinMargin === null">{{ formatString('make_predictions_draw_text') }}</div>
              </td>
              <td class="text-center">{{ result.Points }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PredictorService from "@/services/predictorService";
import {mapMutations, mapState} from "vuex";
import {translationsFormatter} from "@/functions/translationsFormatter";

export default {
  name: "RoundLeaderboard",
  data() {
    return {
      userPredictionsPopup: false,
      numberRoundEntries: undefined,
    }
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'user', 'selectedSport']),
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setSelectedSport']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders);
    },
    fetchBanner() {
      return require(`../brand/${this.brandConfig}/assets/results-main-banner.png`)
    },
    async getRoundLeaderboard() {
      let roundLeaderboardResponse = await PredictorService.getRoundLeaderboard(`${this.user.msisdn}`, `${this.$route.params.roundId}`);
      this.roundResults = roundLeaderboardResponse.data.roundPredictions;
      this.currentUser = this.roundResults.find(element => element.MSISDN === this.user.msisdn);
      this.cleanLeaderboard();
    },
    cleanLeaderboard() {
      this.cleanedLeaderboard = this.roundResults.reduce((unique, o) => {
        if (!unique.some(obj => obj.MSISDN === o.MSISDN)) {
          unique.push(o);
        }
        return unique
      }, [])
      this.sliceLeaderboard();
    },
    sliceLeaderboard() {
      if (this.cleanedLeaderboard[0].PrizeValue === 35) {
        this.cleanedLeaderboard = this.cleanedLeaderboard.slice(0, 20)
      }
    },
    getCorrectPositionAndOrdinalSuffix(number) {
      let firstNumber = number % 10,
          secondNumber = number % 100;
      if (firstNumber === 1 && secondNumber !== 11) {
        return number + "st";
      }
      if (firstNumber === 2 && secondNumber !== 12) {
        return number + "nd";
      }
      if (firstNumber === 3 && secondNumber !== 13) {
        return number + "rd";
      }
      return number + "th";
    },
    async checkUserPredictions(user) {
      let userPredictionsResponse = await PredictorService.getPlayerRoundPredictionsLive(`${user.MSISDN}`, `${user.RoundId}`);
      this.userPredictionsAndPoints = userPredictionsResponse.data.roundPredictions;
      this.userPredictionsPopup = !this.userPredictionsPopup;
    },
    openCloseUserPointsResultsPopUp() {
      this.userPredictionsPopup = this.userPredictionsPopup === false;
    },
    shortenRugbyTeamNames(teamName) {
      teamName = teamName.replace(' Rugby', "");
      return teamName
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.getRoundLeaderboard();
    this.setIsLoading(false);
  },
}
</script>

<style scoped>
.body-container {
  overflow-y: scroll;
  padding-bottom: 50px;
  overflow-x: hidden;
  background-color: #e8e8e8;
  background-size: 100%;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.leaderboard-header-text {
  font-weight: 700;
}

.user-place-container {
  position: relative;
}

.main-banner {
  width: 100%;
}

.popup-table-row {
  height: 30px;
}

.position-circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  width: 105px;
  height: 105px;
  border-radius: 50%;
  background-color: #FFFFFF;
  border: solid 4px;
}

.badge-icon {
  width: 24px;
}

table {
  width: 100%;
}

.my-position-text {
  font-size: 10px;
  font-weight: 700;
}

.user-position-number {
  font-weight: 700;
  font-size: 25px;
}

.sport-name-highlighted {
  width: 100%;
  border-radius: 15px;
  padding: 4px;
  font-size: 14px;
  font-weight: 600;
}

.sport-name-default {
  margin-top: 2px;
  color: #a8a8a8;
  font-size: 14px;
  font-weight: 600;
}

.table-header {
  font-size: 10px;
  font-weight: 600;
}

tbody {
  font-size: 10px;
  font-weight: 700;
}

.user-icon {
  height: 15px;
}

.username-text {
  padding-left: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-predictions-popup {
  z-index: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup-header-text {
  font-size: 12px;
  font-weight: 600;
  padding-left: 13px;
  padding-top: 10px;
}

.popup-table {
  width: 95%;
  font-size: 10px;
}

.table-head-popup {
  border-bottom: 1px solid #FFFFFF;
}

.table-body-popup {
  font-weight: 500;
}

.exit-popup-img {
  position: absolute;
  right: -15px;
  top: -15px;
  width: 40px;
}

tr {
  height: 30px;
  font-size: 12px;
}

.table-row {
  height: 30px;
}

@media screen and (max-width: 392px) {
  .position-circle {
    width: 100px;
    height: 100px;
  }

  .user-position-number {
    font-size: 22px;
  }
}

</style>

import dotenv from 'dotenv';

dotenv.config();

export const productionConfig = {
    predictorService: `${process.env.VUE_APP_PREDICTOR_SERVICE_LIVE}`,
    fantasyHost: `${process.env.VUE_APP_FANTASY_SERVICE}`,
    predictorHost: `${process.env.VUE_APP_PREDICTOR_HOST}`,
    freePlayPredictorHost: `${process.env.VUE_APP_FREEPLAY_PREDICTOR_HOST}`,
    upstreamService: `${process.env.VUE_APP_UPSTREAM_SERVICE}`,
    entryPortalHost: `${process.env.VUE_APP_ENTRY_PORTAL}`,
    rugbyHost: `${process.env.VUE_APP_RUGBY_HOST}`,
    proxyServiceUrl: `${process.env.VUE_APP_PROXY_SERVICE}`,
    hyveAwsUrl: `${process.env.VUE_APP_HYVE_AWS}`,
    hyveAwsApiKey: `${process.env.VUE_APP_HYVE_AWS_API_KEY}`,
    hyveAwsAccessKey: `${process.env.VUE_APP_HYVE_ACCESS_KEY}`,
    hyveAwsAccessSecret: `${process.env.VUE_APP_HYVE_ACCESS_SECRET}`,
    rubgyRedirectUrl: `${process.env.VUE_APP_RUGBY_REDIRECT_URL}`,
    statsComApiKey: `${process.env.VUE_APP_STATS_COM_API_KEY}`,
    statsComApiSecret: `${process.env.VUE_APP_STATS_COM_API_SECRET}`,
}

export const stagingConfig = {
    predictorService: `${process.env.VUE_APP_STAGING_PREDICTOR_SERVICE}`,
    fantasyHost: `${process.env.VUE_APP_FANTASY_SERVICE}`,
    predictorHost: `${process.env.VUE_APP_PREDICTOR_HOST}`,
    freePlayPredictorHost: `${process.env.VUE_APP_FREEPLAY_PREDICTOR_HOST}`,
    upstreamService: `${process.env.VUE_APP_UPSTREAM_SERVICE}`,
    entryPortalHost: `${process.env.VUE_APP_ENTRY_PORTAL}`,
    rugbyHost: `${process.env.VUE_APP_RUGBY_HOST}`,
    proxyServiceUrl: `${process.env.VUE_APP_PROXY_SERVICE}`,
    hyveAwsUrl: `${process.env.VUE_APP_HYVE_AWS}`,
    hyveAwsApiKey: `${process.env.VUE_APP_HYVE_AWS_API_KEY}`,
    hyveAwsAccessKey: `${process.env.VUE_APP_HYVE_ACCESS_KEY}`,
    hyveAwsAccessSecret: `${process.env.VUE_APP_HYVE_ACCESS_SECRET}`,
    rubgyRedirectUrl: `${process.env.VUE_APP_RUGBY_REDIRECT_URL}`,
    statsComApiKey: `${process.env.VUE_APP_STATS_COM_API_KEY}`,
    statsComApiSecret: `${process.env.VUE_APP_STATS_COM_API_SECRET}`,
}


const environment = getEnvironnment()

export const environmentConfig = getEnvObject();


function getEnvironnment() {
    var base_url = window.location.origin;
    if (base_url.includes('staging')) return 'staging'
    if (base_url.includes('localhost')) return 'production'
    else return 'production'
}

function getEnvObject() {
    if (environment === 'staging') return stagingConfig
    if (environment === 'development') return productionConfig
    if (environment === 'production') return productionConfig
}

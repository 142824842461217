<template>
  <div class="d-flex flex-column align-items-center mt-4">
    <div class="d-flex flex-column justify-content-center align-items-center col-4 secondary-bg-colour primary-text games-to-play-container">
      {{ formatString('sport_bar_games_to_play_count_text', [this.activeSport.length]) }}
    </div>
    <div class="d-flex flex-row justify-content-center league-container pt-4" @click="routeToSport(this.activeSport[0].SportName, this.activeSport[0].SportId, this.activeSport)">
      <div class="d-flex flex-column text-center justify-content-center win-data primary-bg-colour col-2">
        <img class="win-data-img" :src="require(`../brand/${this.brandConfig}/assets/win-data-amount-icon.png`)">
      </div>
      <div class="d-flex flex-row text-center align-items-center league-title secondary-bg-colour primary-text col-8">
        <img class="sport-icon-img" :src="require(`../assets/game/${this.activeSport[0].SportName.toLowerCase()}-icon.png`)">
        <div class="sport-text col-8">{{ this.activeSport[0].SportName }}</div>
      </div>
      <div class="predict-button primary-text col-2">
        {{ formatString('sport_bar_play_button_text') }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {translationsFormatter} from "@/functions/translationsFormatter";

export default {
  name: "SportBar",
  inheritAttrs: false,
  props: ['activeSport'],
  data() {
    return {}
  },
  computed: {
    ...mapState(['brandConfig', 'selectedSportsLeagues']),
  },
  methods: {
    ...mapMutations(['setSelectedSport', 'setSportsLeagues']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders)
    },
    async routeToSport(sportName, sportId, sport) {
      this.setSportsLeagues(sport);
      this.setSelectedSport(sportName);
      this.$router.push({name: 'LeagueSelection', params: {sportName: sportName.toLowerCase()}})
    },
  }
}
</script>

<style scoped>

.league-container {
  display: flex;
  justify-content: center;
  width: 108%;
  position: relative;
}

.games-to-play-container {
  height: 16px;
  border-radius: 10px 10px 0 0;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: -23px;
}

.win-data {
  position: relative;
  height: 55px;
  border-radius: 25%;
  z-index: 2;
}

.sport-icon-img {
  position: absolute;
  width: 13%;
  left: 13%;
}

.win-data-img {
  margin-bottom: 5px;
  left: 3px;
  position: absolute;
  width: 100%;
}

.league-title {
  position: relative;
  height: 55px;
  margin-left: -20px;
  border-radius: 0 15px 15px 0;
  z-index: 1;
}

.sport-text {
  padding-left: 20%;
  font-weight: 700;
}

.predict-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  background-color: #019601;
  writing-mode: vertical-lr;
  margin-left: -20px;
  z-index: 0;
  border-radius: 15px 0 0 15px;
  -webkit-transform: rotate(-180deg);
}

</style>

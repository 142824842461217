<template>
  <div class="d-flex flex-column align-items-center pt-3">
    <div class="d-flex flex-column justify-content-center align-items-center col-4 secondary-bg-colour primary-text games-to-edit-container">
      {{ formatString('entered_rounds_bar_games_edit_text', [this.gamesToEdit.length]) }}
    </div>
    <div @click="editButtonClicked(this.activeLeagueEdit)" class="d-flex flex-row justify-content-center league-container pt-4">
      <div class="d-flex flex-column text-center justify-content-center win-data primary-bg-colour col-2">
        <img class="win-data-img" :src="require(`../brand/${this.brandConfig}/assets/win-data-amount-icon.png`)">
      </div>
      <div class="d-flex flex-row align-items-center league-title secondary-bg-colour primary-text col-8">
        <img class="league-icon-img" :src="this.findImageModule(this.activeLeagueEdit.LeagueName)">
        <div class="league-text m-1 col-10">{{ this.activeLeagueEdit.LeagueName }}</div>
      </div>
      <div class="edit-button primary-bg-colour primary-text col-2">
        {{ formatString('entered_rounds_bar_edit_text') }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {translationsFormatter} from "@/functions/translationsFormatter";
import PredictorService from "@/services/predictorService";
import {environmentConfig} from "@/config/enviromentConfig";

export default {
  name: "EnteredRoundBar",
  inheritAttrs: false,
  props: ['activeLeagueEdit'],
  data() {
    return {
      gamesToEdit: [],
      todayDate: new Date().toISOString()
    }
  },
  computed: {
    ...mapState(['brandConfig', 'user']),
  },
  methods: {
    ...mapMutations(['setSelectedSportLeague']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders)
    },
    findImageModule(teamName) {
      let teamNameReformat = teamName.split(" ").join("-").toLowerCase()
      try {
        return [require(`../assets/leagueLogos/${teamNameReformat}-logo-default.png`) || require(`../assets/leagueLogos/${teamNameReformat}-soccer-logo-default.png`)]
      } catch (e) {
        return [require(`../assets/game/default-badge.png`)]
      }
    },
    async countFixturesInRound() {
      const fixturesResponse = await PredictorService.getLeagueFixtures(`${this.user.msisdn}`, `${this.activeLeagueEdit.RoundID}`)
      const fixturesInRound = fixturesResponse.data.fixtures

      this.gamesToEdit = fixturesInRound.filter((fixture) => {
        const fixtureDate = new Date(fixture.StartDate).toISOString();
        return fixtureDate > this.todayDate;
      });
    },
    editButtonClicked(leagueFixture) {
      this.setSelectedSportLeague(leagueFixture.LeagueAbbreviation);
      this.$router.push({name: `MakePredictions${leagueFixture.SportName}`, params: {roundId: leagueFixture.RoundID}});
    }
  },
  async beforeMount() {
    await this.countFixturesInRound()
  },
}
</script>

<style scoped>
.league-container {
  display: flex;
  justify-content: center;
  width: 108%;
  position: relative;
}

.games-to-edit-container {
  height: 16px;
  border-radius: 10px 10px 0 0;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: -23px;
}

.win-data {
  position: relative;
  height: 55px;
  border-radius: 25%;
  z-index: 2;
}

.win-data-img {
  margin-bottom: 5px;
  left: 3px;
  position: absolute;
  width: 100%;
}

.league-title {
  position: relative;
  height: 55px;
  margin-left: -20px;
  border-radius: 0 15px 15px 0;
  z-index: 1;
}

.edit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  writing-mode: vertical-lr;
  margin-left: -20px;
  z-index: 0;
  border-radius: 15px 0 0 15px;
  -webkit-transform: rotate(-180deg);
}

.league-text {
  padding-left: 30%;
  font-weight: 700;
  font-size: 12.5px;
}

.league-icon-img {
  position: absolute;
  left: 25px;
  height: 35px;
}
</style>

<template>
  <div class="unsubscribe-page" v-if="!this.isLoading">
    <div class="d-flex flex-column justify-content-center text-center primary-bg-colour primary-text unsubscribe-header">
      <img @click="backButtonClicked" :src="require(`../brand/${brandConfig}/assets/left-keyboard-arrow.png`)"
           class="go-back-key">{{ formatString('unsubscribe_header_text') }}
    </div>
    <div class="d-flex flex-column justify-content-center text-center p-4 secondary-bg-colour primary-text subscription-status-text">
      {{ formatString('unsubscribe_status_text', [brandConfig]) }}
    </div>
    <div class="d-flex align-items-center justify-content-center circle-container pt-5 pb-4">
      <div class="d-flex flex-column justify-content-center primary-bg-colour primary-text exclamation-mark-circle">
        !
      </div>
    </div>
    <div class="d-flex flex-column text-center p-4 pt-5 pb-5 unsubscribe_confirm_unsubscription_text">
      {{ formatString('unsubscribe_confirm_unsubscription_text', [brandConfig]) }}
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center">
      <button @click="() => confirmButtonClicked()" class="mt-4 col-10 primary-bg-colour primary-text save-button">
        {{ formatString('unsubscribe_confirm_button_text') }}
      </button>
      <button @click="backButtonClicked" class="mt-1 mb-2 col-5 secondary-bg-colour primary-text back-button">
        {{ formatString('unsubscribe_back_button_text') }}
      </button>
    </div>
  </div>
</template>

<script>
import {translationsFormatter} from "@/functions/translationsFormatter";
import {mapState, mapMutations} from "vuex";
import UpstreamService from "@/services/upstreamService";

export default {
  name: "CancelPackage",
  data() {
    return {}
  },
  beforeMount() {
    this.setIsLoading(false);
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders);
    },
    backButtonClicked() {
      this.$router.push('/settings');
    },
    async confirmButtonClicked() {
      await UpstreamService.unsubscribe(this.user.msisdn, this.user.token, this.user.revenuePartnerId);
      localStorage.removeItem("token");
      window.open('https://vodacomfantasy.com', '_self')
    },
  }
}
</script>

<style scoped>
.unsubscribe-page {
  background-color: #e8e8e8;
  overflow-y: scroll;
  padding-bottom: 20px;
}

.unsubscribe-page::-webkit-scrollbar {
  display: none;
}

.unsubscribe-header {
  position: relative;
  font-weight: 600;
  font-size: 18px;
  height: 45px;
}

.go-back-key {
  position: absolute;
  height: 20px;
  left: 27px;
  top: 13px;
}

.subscription-status-text {
  height: 70px;
  border-radius: 0 0 20px 20px;
  font-size: 14px;
  font-weight: 500;
}

.exclamation-mark-circle {
  position: absolute;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 80px;
  padding-bottom: 10px;
  padding-left: 44px;
}

.circle-container {
  height: 40%;
  position: relative;
  margin-top: 32px;
  margin-bottom: 32px;
}

.unsubscribe_confirm_unsubscription_text {
  font-weight: 700;
}

.save-button {
  height: 45px;
  border: 0;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
}

.back-button {
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  border: 0;
}


</style>

export const translations = {
    "footer_profile_icon_text": "الملف التعريفي",
    "footer_leaderboard_icon_text": "لوحة المتصدرين",
    "footer_play_icon_text": "العب",
    "footer_my_rounds_icon_text": "جولاتي",
    "footer_trophy_icon_text": "النتائج",
    "profile_header_text": "ملفي التعريفي",
    "profile_airtime_won_text": "وقت البث",
    "profile_play_text": "العب",
    "profile_data_won_label": ' بيانات ميجابايت',
    "profile_prizes_won_label": 'PRIZES WON',
    "profile_total_season_points_header": "إجمالي نقاط الموسم",
    "profile_rounds_entered_header": "عدد الجولات التي تم الدخول فيها",
    "profile_top_predictors_header": "أفضل المتوقعون",
    "profile_top_hundred_header": "أفضل 100 هدف",
    "profile_how_to_play_button_text": "كيفية اللعب",
    "sidebar_menu_how_to_play_icon_text": "كيفية اللعب",
    "sidebar_menu_trophy_icon_text": "الجوائز",
    "sidebar_menu_terms_conditions_icon_text": "الأحكام والشروط",
    "sidebar_menu_more_games_title": "ألعاب أخرى",
    "sidebar_menu_rugby_icon_text": "رجبي فانتازي",
    "sidebar_menu_football_icon_text": "كرة القدم فانتازي",
    "sidebar_menu_free_play_icon_text": "freeplay predictor",
    "round_selection_header_text": "ممارسة اللعبة اليوم: {0}",
    "round_selection_select_sport_text": "اختر رياضة",
    "sport_bar_games_to_play_count_text": "الدوريات التي ستلعب فيها: {0}",
    "sport_bar_play_button_text": 'العب',
    "league_select_text": 'اختر الدوري',
    "league_select_predict_text": 'توقع',
    "league_bar_games_to_play_count_text": 'المباريات التي ستتوقعها: {0}',
    "league_bar_predict_text": 'توقع',
    "make_predictions_header_text": 'توقع النتائج',
    "make_predictions_submit_button": 'إرسال التوقعات',
    "make_predictions_earn_points_button_text": 'كيف أكسب النقاط؟',
    "make_predictions_earn_points_popup_header_text": 'الق نظرة على التفاصيل بالأسفل عن كيفية الحصول على النقاط:',
    "make_predictions_earn_points_table_header_description": 'الوصف',
    "make_predictions_earn_points_table_header_points": 'النقاط',
    "make_predictions_earn_points_table_row_home": 'نتيجة المباراة على ملعبه',
    "make_predictions_earn_points_table_row_away": 'نتيجة المباراة خارج ملعبه',
    "make_predictions_earn_points_table_row_result": 'النتيجة',
    "make_predictions_earn_points_table_row_correct": 'جميع التوقعات صحيحة',
    "make_predictions_earn_points_table_row_correct_team": 'الفريق الصواب',
    "make_predictions_earn_points_table_row_win_margin": 'هامش الربح (في حدود 5)',
    "make_predictions_earn_points_table_row_exact_margin": 'الهامش بالتحديد',
    "make_predictions_earn_points_table_row_draw": 'التعادل',
    "make_predictions_no_team_selected": 'خطأ: "لم تحدد أي اختيار',
    "my_rounds_primary_header_text": 'جولاتي',
    "my_rounds_secondary_header_text": 'عدل التوقعات',
    "my_rounds_published_rounds_header_text": 'الجولات المعلنة:',
    "entered_rounds_bar_edit_text": "تعديل",
    "entered_rounds_bar_games_edit_text": 'عدد الألعاب التي ستعدلها: {0}',
    "my_results_primary_header_text": 'توقع{0}',
    "my_results_secondary_header_text": 'النتائج',
    "my_results_round_header": 'الجولة',
    "my_results_table_header_teams": 'الفرق',
    "my_results_table_header_result": 'نتيجة المباراة',
    "my_results_table_header_prediction": 'توقّعك',
    "my_results_table_header_points": 'نقاطك',
    "published_rounds_bar_view_button_text": 'عرض',
    "leaderboard_page_header": 'لوحة المتصدرين',
    "leaderboard_page_play_circle_text": 'العب',
    'leaderboard_page_you_won_text': 'لقد فزت',
    'leaderboard_my_position_text': 'وضعي',
    'user_winnings_table_header_one': 'الجولة',
    'user_winnings_table_header_two': 'الوضع',
    'user_winnings_table_header_three': 'النقاط',
    'user_winnings_table_header_four': 'الجائزة التي فزت بها',
    "round_leaderboard_table_header_one": 'الترتيب',
    "round_leaderboard_table_header_two": 'اللاعب',
    "round_leaderboard_table_header_three": 'النقاط',
    "round_leaderboard_table_header_four": 'البيانات التي فزت بها',
    "round_leaderboard_data_amount": '250 ميجابايت',
    "round_leaderboard_popup_header_text": 'ملخص نقاط اللاعب',
    "round_leaderboard_popup_point_one": 'المباراة',
    "round_leaderboard_popup_point_two": 'تم التوقع بها',
    "round_leaderboard_popup_point_three": 'النقاط',
    "settings_header_text": 'الإعدادات',
    "settings_change_username_button_text": 'تغيير اسم المستخدم',
    "settings_change_notifications_button_text": 'تنبيهات',
    "settings_change_cancel_package_button_text": 'إلغاء الحزمة',
    "unsubscribe_header_text": 'إلغاء الحزمة',
    "unsubscribe_status_text": 'أنت الأن مشترك في فوداكام فانتازي ليج',
    "unsubscribe_confirm_unsubscription_text": ' أوكد أنني أرغب في إلغاء حزمتي مع فوداكام فانتازي ليج',
    "unsubscribe_confirm_button_text": 'تأكيد',
    "unsubscribe_back_button_text": 'الرجوع',
    "username_header_text": 'قم بتغيير اسم المستخدم',
    "username_explanation_content_text": 'يُرجى إدخال اسم المستخدم الجديد الذي ترغب في أن يُشار لك به في لوحة المتصدرين',
    "username_current_username_text": 'اسم المستخدم الحالي:',
    "username_input_placeholder_text": 'ادخل اسم المستخدم الجديد',
    "username_min_max_character_text": 'يجب أن يتكون اسم المستخدم من مزيج من الحروف والأرقام ويتراوح بين 6 إلى 14 حرفاً',
    "username_change_username_button_text": 'تغيير اسم المستخدم',
    "username_back_button_text": 'الرجوع',
    "notifications_header_text": 'تنبيهات',
    "notifications_manage_text": 'قم بإدارة تنبيهاتك',
    "notifications_checkbox_text": 'رسائل الخدمات',
    "notifications_service_updates_message": 'أرغب في استلام تحديثات عن خدماتكم أو العروض الترويجية التي نقوم بها',
    "notifications_save_button_text": 'الحفظ',
    "notifications_back_button_text": 'الرجوع',
    "how_to_play_header": "كيفية اللعب",
    "how_to_play_general_header_text": 'عام',
    "how_to_play_general_info_point_one": 'مسابقات فانكلاش هي ألعاب مهارية تتطلب امتلاك قدر من المهارة والمعرفة بالمعلومات المتصلة بالرياضات الاحترافية بالإضافة إلى إلمامك بقواعد فانكلاش ذاتها حتى تتمكن من تجميع نقاط المتوقّع;',
    "how_to_play_general_info_point_two": 'يتم تجميع نقاط المتوقّع من خلال الأداء الحقيقي للفرق على مستوى عدة تخصصات رياضية ودوريات;',
    "how_to_play_edit_lineup_header_text": 'تعديل توقّع الجولة',
    "how_to_play_edit_lineup_info": 'يمكنك تعديل توقعات الجولة في أي مرحلة حتى بداية المسابقة وذلك من خلال النقر على خيار تعديل في شاشة الدخول. ولكن، بمجرد أن تبدأ المسابقة، لن تكون توقعاتك قابلة للتعديل.',
    "how_to_play_entry_fees_header_text": 'رسوم الدخول',
    "how_to_play_entry_fees_info": "'لا توجد رسوم دخول للمسابقات المحدّدة. 'سيكون بمقدور المشتركين في خدمة فانكلاش الدخول في مسابقات أسبوعية ويومية طالما كان اشتراكهم في المحتوى نشطاً",
    "how_to_play_entry_cancellation_header_text": 'إلغاء الدخول',
    "how_to_play_entry_cancellation_info": 'تحتفظ فانكلاش بالحق في إلغاء أي لعبة قبل موعد انطلاقها. عند تعرض عدة مباريات لها نفس الشروط (مثل المباريات الحقيقية، أو عدد اللاعبين، على سبيل المثال لا الحصر) للإلغاء نتيجة لانخفاض عدد المتسابقين للغاية، يُمكن لفانكلاش تجنب الإلغاء من خلال ضم مجموعات اللاعبين في هذه المباريات معاً. ولكن، لن تدمج فانكلاش أبدأً مجموعات اللاعبين التي لها شروط دخول مختلفة؛',
    "how_to_play_cancel_contests_header_text": 'إلغاء المسابقات',
    "how_to_play_cancel_contests_info": "تحتفظ فانكلاش بالحق في إلغاء المسابقات حسب تقديرها، ودون أي قيود على ذلك. 'سيتم ذلك فقط عندما يكون لدينا اعتقاد بأنه سيكون هناك تأثير كبير على نزاهة المسابقات نتيجة للمشكلات التي يتعرض لها الموقع أو لتأثير الأحداث الحقيقية على المسابقات",
    "how_to_play_contest_term_header_text": 'شروط المسابقة',
    "how_to_play_game_cancel_header_text": 'الإلغاء والتأجيل',
    "how_to_play_game_cancel_info": 'في حالة إلغاء مباراة حقيقية مجدولة، أو تقديم موعدها ("قدم الموعد")، أو تأجيلها لأي سبب من الأسباب، لن يمنح اللاعبين أي نقاط في حالة إلغاء المباراة أو الحدث أو تقديم أو تأخير موعده',
    "how_to_play_prizes_header_text": 'الجوائز',
    "how_to_play_contest_prizes_point_one": 'تقدم فانكلاش العديد من أنواع المسابقات المختلفة. تُعلن فانكلاش الجوائز المقدمة في كل مسابقة في بدايتها;',
    "how_to_play_contest_prizes_point_two": 'لا تخضع الجوائز للتغيير استنادًا إلى عدد المتسابقين أو لإجمالي رسوم الدخول الخاصة بالمسابقة;',
    "how_to_play_contest_prizes_point_three": "'تُمنح الجوائز للمتسابقين الذين يجمعون أكبر عدد من نقاط المتوقّع كما هو وارد في تفاصيل المسابقة، بعد نهاية كل مسابقة. 'لن تحدد فانكلاش مطلقاً الفائز استنادًا إلى النتيجة، أو فارق النقاط، أو أداء أي فريق أو مجموعة فرق حقيقية أو إلى مزيج مما سبق",
    "how_to_play_contest_prizes_point_four": 'وعندما يفوز أحد المتسابقين في مسابقة، سيجري منح الجائزة مباشرة إلى العميل في صورة رصيد بيانات. وبالنسبة للجوائز بخلاف رصيد البيانات، سيتم الاتصال بالشخص مباشرة وفقاً للشروط والأحكام المرتبطة بهذه الجائزة على وجه الخصوص.',
    "how_to_play_contest_prizes_point_five": "يمكن أن تقدم فانكلاش مباريات مجانية لأغراض الترفيه، دون وجود خيار لتقديم جائزة أو جوائز. سيجري ذكر ذلك بوضوح منذ البداية",
    "how_to_play_contest_prizes_point_six": 'يستند حساب الجوائز على نتائج المسابقات عند وقت إعلان فانكلاش للنتيجة النهائية للنقاط.',
    "how_to_play_contest_prizes_point_seven": 'تستند النقاط التي يتم الحصول عليها على الإحصائيات من تغذية البيانات التي يوفرها مورد خارجي للإحصائيات ("مورد الإحصائيات"). وعلى الرغم من أن فانكلاش تبذل قصارى جهدها لضمان توفير خدمة ذات سمعة طيبة، إلا أنها تعتمد حصرياً على المعلومات التي يوفرها مورد الإحصائيات ولا تتحمل المسؤولية مطلقاً نتيجة للبيانات الخاطئة التي تتلقاها من أي مورد للبيانات.',
    "how_to_play_contest_prizes_point_eight": 'بموجب هذا، فإنك تعفي فانكلاش وتحميها ضد جميع المطالبات، التي تنشأ بشكل مباشر أو غير مباشر عن البيانات الخاطئة التي تتلاقاها فانكلاش من موردها للإحصائيات;',
    "how_to_play_contest_prizes_point_nine": 'تنتظر فانكلاش مرور فترة زمنية محدّدة، لتأكيد الفائز في المسابقة بعد انتهاء المباراة النهائية في المسابقة حتى يتمكن مورد الإحصائيات من تصحيح أي أخطاء رئيسية في نظامه أولا;',
    "how_to_play_contest_prizes_point_ten": 'يرجى ملاحظة إنه بمجرد منح الجوائز للفائزين، لن يتم تغيير النتائج بصرف النظر عن أي تعديلات رسمية تدخلها الدوريات الرياضية الاحترافية.',
    "how_to_play_contest_prizes_point_eleven": 'ولا يُعدّ ذلك نفس الموقف كما هو في حالة وجود ضرورة للتغيير بعد التسوية، نتيجة لوجود مشكلة في عملية التسوية ذاتها. وفي مثل هذه الحالات، يمكن تغيير عملية التسوية (بما في ذلك منح الجوائز أو رصيد البيانات) وتغيير نتيجة المباريات بشكل صحيح.',
    "how_to_play_contest_prizes_point_twelve": 'لا يجوز استبدال الجوائز أو تحويلها. تخضع جميع الضرائب المرتبطة باستلام الجائزة واستخدامها لمسؤولية الفائز وحده. في حالة اعتراض أي جهة قانونية على الجوائز المقدّمة، يحق لفانكلاش، حسب تقديرها الخاص، تقرير ما إذا كانت ستمنح هذه الجواز من عدمه.',
    "how_to_play_contest_prizes_point_thirteen_point_header": 'حالات التعادل',
    "how_to_play_contest_prizes_point_thirteen_point_one": "في حالة حدوث تعادل في إحدى المسابقات بين مستخدمي فانكلاش ستُقسم الجائزة بالتساوي بين جميع اللاعبين المتعادلين",
    "how_to_play_contest_prizes_point_thirteen_point_two": 'في حالة حدوث تعادل بين اللاعبين حيث لا يمكن تقسيم الجائزة (الجوائز) بالتساوي، فستستخدم المشاركة التي بها أعلى الرياضيين الذين أحرزوا أهدافاً فردية بمثابة نقطة ترجيح لكسر التعادل، يليها ثاني الرياضيين تهديفاً، ثم الثالث، وما إلى ذلك، حتى يتم كسر التعادل وترجيح الفائز. في حالة استمرت حالة التعادل دون ترجيح للفائز، سيتم إعلان اللاعب الذي قدم فريقه أولا بمثابة الفائز.',
    "how_to_play_notifications_header_text": 'تنبيهات',
    "how_to_play_notifications_info": 'يجري في العادة إخطار اللاعبين بواسطة رسالة نصية قصيرة في غضون يوم عمل واحد. بموجب هذا توافق على نشر اسم المستخدم الخاص بك وموقع بلدك في حالة كنت أنت الفائز. يُمكن أن يُطلب من الفائزين إرسال شهادة أهلية والنماذج الضريبية المناسبة عن طريق البريد الإلكتروني أو البريد العادي. يُمكن أن يؤدي عدم امتثال الفائز لهذه الطلبات إلى حرمانه من الفوز. يُمكن أن ينتج عن أي إخطار بالفوز بالجائزة يعاد إلينا على إنه لم يتم تسليمه في حرمان الفائز من الجائزة واختيار فائز بديل. يمكن الحصول على قائمة بالفائزين في كل فترة تنافسية بموجب طلب كتابي يوجه إلى: Fanclash: hello@thefanclash.com',
    "how_to_play_point_scoring_header_text": 'تسجيل النقاط',
    "how_to_play_point_scoring_system_header_text": 'نظام تسجيل نقاط المتوقّع',
    "how_to_play_contest_term_info": 'ستجري المسابقات على مدار يوم واحد، أو عدة أيام أو أطول من ذلك كما هو محدد في قواعد المسابقة. يُحدد الفائزين باستخدام المعايير المذكورة في قواعد اللعبة',
    "prizes_header_text": 'الجوائز',
    "prizes_promo_info_text": 'احظى بفرصة الفوز برصيد بيانات أو غير ذلك من الجوائز الرائعة عندما تشارك في اللعب ضمن دوري فوداكومب للتوقع بالنتائج',
    "prizes_first_place_header_text": 'الجوائز من 1 إلى 20',
    "prizes_first_place_airtime_amount": 'رصيد بيانات مقداره 250 ميجابايت',
    'round_selection_entered_all_rounds_text': 'لقد قمت بإدخال جميع الجولات القادمة. لاستعراض توقعاتك النشطة، يُرجى الانتقال إلى،',
    'round_selection_entered_all_rounds_text_bold': 'جولاتي',
    'my_rounds_no_predictions_text_bold': 'العب',
    'my_rounds_no_predictions_text': 'لم تقدم أي توقعات حتى الأن. لتقديم التوقعات، يُرجى الانتقال إلى',
    'my_results_no_rounds_entered_text': 'لا توجد نتائج سابقة للجولات',
    'make_predictions_win_margin_text': 'قم باختيار الفائز وهامش الفوز',
    "make_predictions_draw_text": 'سحب',
    "make_predictions_win_type_text_cricket": 'Select a winner and type of win',
    "make_predictions_earn_points_table_row_win_margin_two": 'Win Margin',
    "make_predictions_cricket_win_by_text": "WIN by:",
    "make_predictions_cricket_win_by_type_narrow": "Narrow",
    "make_predictions_cricket_win_by_type_comfortable": "Comfortable",
    "make_predictions_cricket_win_by_type_thrashing": "Thrashing",
    "make_predictions_cricket_win_by_type_narrow_wickets": "0-2 Wickets or",
    "make_predictions_cricket_win_by_type_narrow_runs": "0-10 Runs",
    "make_predictions_cricket_win_by_type_comfortable_wickets": "3-5 Wickets or",
    "make_predictions_cricket_win_by_type_comfortable_runs": "11-25 Runs",
    "make_predictions_cricket_win_by_type_thrashing_wickets": "6+ Wickets or",
    "make_predictions_cricket_win_by_type_thrashing_runs": "26+ Runs",
    "make_predictions_edit_predictions_text": 'Edit Predictions',
    "round_leaderboard_table_player_username_default": 'Player',
    'sidebar_menu_terms_soccer_news_text': 'soccer news',
}

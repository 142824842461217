<template>
  <div class="d-flex header row justify-content-center">
    <Header/>
  </div>
  <div class="d-flex row justify-content-center content-body">
    <LoadingOverlay v-if="this.isLoading"/>
    <router-view class="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-3"></router-view>
  </div>
  <div class="d-flex row justify-content-center">
    <Footer class="d-flex col col-12 col-sm-9 col-md-7 col-lg-6 col-xl-4"/>
  </div>
</template>

<script>

import Header from "./components/Header";
import Footer from "./components/Footer";
import {mapState, mapMutations} from "vuex";
import {setBrandConfig} from "@/functions/setBrandConfig";
import UpstreamService from "@/services/upstreamService";
import PredictorService from "@/services/predictorService";
import LoadingOverlay from "@/components/LoadingOverlay";

export default {
  components: {Header, Footer, LoadingOverlay},
  computed: {
    ...mapState(['isLoading', 'brandConfig'])
  },
  data() {
    return {}
  },
  async beforeMount() {
    setBrandConfig();
    const urlParams = new URLSearchParams(window.location.search);
    const localStorageToken = localStorage.getItem('token')
    const fantasyToken = urlParams.get('fantasyToken') ? urlParams.get('fantasyToken') : localStorageToken ? localStorageToken : undefined;
    if (fantasyToken) {
      const user = await UpstreamService.getUser(`${fantasyToken}`);
      if (user.data.token === 'invalidToken') {
        localStorage.removeItem("token");
        window.open('https://vodacomfantasy.com', '_self')
      } else {
        const msisdn = user.data.msisdn;
        const stats = await PredictorService.getUserLandingPageStats(`${msisdn}`);
        this.setUserLandingStats(stats.data)
        this.setUser(user.data)
      }
    }
    this.$router.push('/profile')
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setUserLandingStats', 'setUser'])
  },
  watch: {}
};
</script>

<style>
Header {
  background-color: #ffffff;
  height: 60px;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 1;
}

.content-body {
  position: absolute;
  height: auto;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 75px;
  z-index: 0;
}

Footer {
  position: fixed;
  bottom: 0;
  border-radius: 15px 15px 0 0;
  background-color: #000000;
  height: 75px;
  color: white;
  font-size: 12px;
  z-index: 1;
}
</style>

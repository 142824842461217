<template>
  <footer class="d-flex flex-column justify-content-center p-3 navbar-bg-colour">
    <div class="d-flex justify-content-evenly mt-4">
      <div @click="routeToProfilePage" class="d-flex flex-column text-center footer-navbar-img">
        <div v-if="!isIconActive(this.$route, '/profile')"><img src="../assets/game/profile-nav-icon.png"></div>
        <div v-if="isIconActive(this.$route, '/profile')"><img :src="require(`../brand/${this.brandConfig}/assets/profile-nav-icon-active.png`)"></div>
        <div :style="isArabic()">{{ formatString('footer_profile_icon_text') }}</div>
      </div>
      <div @click="routeToLeaderboardPage" class="d-flex flex-column text-center footer-navbar-img">
        <div v-if="!isLeaderboardIconActive(this.$route)"><img src="../assets/game/leaderboard-nav-icon.png"></div>
        <div v-if="isLeaderboardIconActive(this.$route)"><img :src="require(`../brand/${this.brandConfig}/assets/leaderboard-nav-icon-active.png`)"></div>
        <div :style="isArabic()">{{ formatString('footer_leaderboard_icon_text') }}</div>
      </div>
      <div @click="routeToRoundSelectionPage" class="d-flex flex-column text-center play-icon-container">
        <div class="navbar-bg-colour half-circle"></div>
        <div v-if="!isPlayIconActive(this.$route)"><img class="play-icon" src="../assets/game/play-nav-icon.png"></div>
        <div v-if="isPlayIconActive(this.$route)"><img class="play-icon" :src="require(`../brand/${this.brandConfig}/assets/play-nav-icon-active.png`)"></div>
        <div class="pt-2">{{ formatString('footer_play_icon_text') }}</div>
      </div>
      <div @click="routeToMyRoundsPage" class="d-flex flex-column text-center footer-navbar-img">
        <div v-if="!isIconActive(this.$route, '/my-rounds')"><img src="../assets/game/my-rounds-nav-icon.png"></div>
        <div v-if="isIconActive(this.$route, '/my-rounds')"><img :src="require(`../brand/${this.brandConfig}/assets/my-rounds-nav-icon-active.png`)"></div>
        <div :style="isArabic()">{{ formatString('footer_my_rounds_icon_text') }}</div>
        <div v-if="this.roundsEntered" class="my-rounds-counter text-center">{{ this.roundsEntered }}</div>
      </div>
      <div @click="routeToResultsPage" class="d-flex flex-column text-center footer-navbar-img">
        <div v-if="!isResultsIconActive(this.$route, '/my-results')"><img src="../assets/game/results-nav-icon.png"></div>
        <div v-if="isResultsIconActive(this.$route, '/my-results')"><img :src="require(`../brand/${this.brandConfig}/assets/results-nav-icon-active.png`)"></div>
        <div :style="isArabic()">{{ formatString('footer_trophy_icon_text') }}</div>
      </div>
    </div>
  </footer>
</template>

<script>
import {translationsFormatter} from "@/functions/translationsFormatter";
import {mapMutations, mapState} from "vuex";
import PredictorService from "@/services/predictorService";

export default {
  name: "Footer",
  computed: {
    ...mapState(['brandConfig', 'userLandingStats', 'user', 'roundsEntered']),
  },
  methods: {
    ...mapMutations(['setUserLandingStats', 'setRoundsEntered']),
    async getRoundsEntered() {
      if (this.user !== undefined) {
        const upcomingSports = (await PredictorService.getActiveSports(`${this.user.msisdn}`, `${this.user.username}`)).data;
        const predictedRounds = upcomingSports.filter(function (sport) {
          return sport.HasPredictions === true && sport.Promo === false && sport.Free === false;
        })
        this.setRoundsEntered(predictedRounds.length);
      }
    },
    isArabic() {
      const language = this.brandConfig.slice(-2)
      if (language === "ar") return "font-size: xx-small; width: 80px;"
      else return ""
    },
    isIconActive(route, iconName) {
      if (route.path === iconName) return true;
    },
    isPlayIconActive(route) {
      const activeRoutes = [
        '/sport-selection',
        'LeagueSelection',
        'MakePredictionsRugby',
        'MakePredictionsSoccer',
        'MakePredictionsCricket'
      ];

      return activeRoutes.includes(route.path) || activeRoutes.includes(route.name);
    },
    isResultsIconActive(route) {
      if (route.name === 'MyResults') {
        return true
      }
    },
    isLeaderboardIconActive(route) {
      if (route.path === '/user-winnings' || route.name === 'RoundLeaderboard') return true;
    },
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders);
    },
    routeToProfilePage() {
      this.$router.push('/profile');
    },
    routeToLeaderboardPage() {
      this.$router.push('/user-winnings');
    },
    routeToMyRoundsPage() {
      this.$router.push('/my-rounds');
    },
    routeToRoundSelectionPage() {
      this.$router.push('/sport-selection');
    },
    routeToResultsPage() {
      this.$router.push('/my-results/1');
    }
  },
  watch: {
    async $route(to, from) {
      if ((from.path.includes('make-predictions') && to.path === '/my-rounds')) {
        await this.getRoundsEntered();
      }
    }
  }
}
</script>

<style scoped>

.play-icon {
  width: 65px;
  height: 65px;
}

.play-icon-container {
  z-index: 1;
  position: relative;
  bottom: 25px;
}

.half-circle {
  z-index: -1;
  width: 90px;
  height: 91px;
  position: absolute;
  border-radius: 45px 45px 0 0;
  left: -18%;
  top: -14%;
}

.my-rounds-nav-icon-img-container img {
  width: 30px;
  height: 30px;
}

.my-rounds-counter {
  position: absolute;
  z-index: 1;
  right: 0%;
  top: -6%;
  background-color: #01a601;
  font-size: 10px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.footer-navbar-img {
  position: relative;
  margin-top: 3%;
  margin-bottom: 7%;
}

.footer-navbar-img img, .footer-navbar-rounds-img img {
  width: 30px;
  height: 30px;
}

</style>


<template>
  <div class="d-flex flex-column body-container" v-if="!this.isLoading">
    <div class="pt-2 pb-2 how-to-play-header-text">
      {{formatString('prizes_header_text')}}
    </div>
    <div class="p-2 text-center promo-text">
      {{formatString('prizes_promo_info_text')}}
    </div>
    <div class="d-flex flex-column align-items-center pt-1">
      <div class=" d-flex flex-row col-11 mt-3 pt-2 pb-2 s secondary-bg-colour primary-text prize-container">
        <img class="prizes-place-icon" :src="require('../assets/game/prizes-first.png')"/>
        <div class="col-10 d-flex flex-column">
          <div class=" how-it-works-text">{{formatString('prizes_first_place_header_text')}}</div>
          <div class="grand-prize-amount">{{formatString('prizes_first_place_airtime_amount')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {translationsFormatter} from "@/functions/translationsFormatter";
import {mapMutations, mapState} from "vuex";

export default {
  name: "PrizesList",
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders);
    },
  }
}
</script>

<style scoped>
.body-container {
  background-color: #e8e8e8;
  overflow-y: scroll;
  padding-bottom: 50px;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.promo-text {
  font-weight: 700;
  font-size: 15px;
}

.grand-prize-container {
  position: relative;
  border-radius: 10px;
  height: max-content;
  padding-left: 60px;
  padding-bottom: 15px;
}

.grand-prizes-header {
  font-weight: 600;
  font-size: 17px;
}

.grand-prize-amount {
  font-size: 14px;
}

.how-it-works-container {
  border-top: 1px solid #FFFFFF;
}

.how-it-works-text {
  font-weight: 600;
  font-size: 14px;
}

.trophy-icon {
  position: absolute;
  height: 40px;
  left: 4%;
  top: 10%;
}

.perfect-pick-explanation-text {
  padding-left: 5px;
  font-size: 11px;
}

.perfect-pick-icon {
  height : 28px;
}

.prize-container {
  border-radius: 10px;
  height: max-content;
}

.prizes-place-icon {
  margin-left: 12px;
  margin-right: 21px;
  height: 40px;
}

</style>

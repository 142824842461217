import {createStore} from 'vuex'

export default createStore({
    state: {
        user: undefined,
        userLandingStats: undefined,
        brandConfig: undefined,
        activeSports: undefined,
        selectedSport: undefined,
        selectedSportsLeagues: undefined,
        isLoading: false,
        selectedSportLeague: undefined,
        rugbyInsightsPopup: false,
        roundsEntered : undefined,
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setUserLandingStats(state, userLandingStats) {
            state.userLandingStats = userLandingStats
        },
        setBrand(state, brandConfig) {
            state.brandConfig = brandConfig
        },
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setActiveSports(state, activeSports) {
            state.activeSports = activeSports
        },
        setSelectedSport(state, selectedSport) {
            state.selectedSport = selectedSport
        },
        setSportId(state, sportId) {
            state.sportId = sportId
        },
        setSportsLeagues(state, selectedSportsLeagues) {
            state.selectedSportsLeagues = selectedSportsLeagues
        },
        setSelectedSportLeague(state, selectedSportLeague) {
            state.selectedSportLeague = selectedSportLeague
        },
        setRugbyInsightsPopup(state, rugbyInsightsPopup) {
            state.rugbyInsightsPopup = rugbyInsightsPopup
        },
        setRoundsEntered(state, roundsEntered) {
            state.roundsEntered = roundsEntered
        },
    },
    actions: {},
    modules: {}
})

import {brandConfigMap} from "@/brand";
import store from "@/store";

export function setBrandConfig() {
    const domain = window.origin;
    const brandConfig = brandConfigMap.get(domain);
    if (!brandConfig) {
        const brandConfig = brandConfigMap.get('default');
        store.commit('setBrand', brandConfig);
    } else {
        store.commit('setBrand', brandConfig);
    }

    const brandStyles = require(`../brand/${brandConfig}/styles/index.js`).styles;
    const brandStyleSheet = document.createElement('style');

    let brandStyleInnerHTML = '';

    brandStyles.backgrounds.forEach((background) => {
        if (brandStyleInnerHTML === '') brandStyleInnerHTML += `.${background.className} { background-color: ${background.colour} }`;
        else brandStyleInnerHTML += ` .${background.className} { background-color: ${background.colour} }`;
    });

    brandStyles.text.forEach((textItem) => {
        brandStyleInnerHTML += ` .${textItem.className} { color: ${textItem.colour} }`;
    });

    brandStyles.borders.forEach((textItem) => {
        brandStyleInnerHTML += ` .${textItem.className} { border-color: ${textItem.colour} }`;
    });

    brandStyleSheet.innerHTML = brandStyleInnerHTML;
    document.body.appendChild(brandStyleSheet);
}

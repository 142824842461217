import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class PredictorService {
    static async getActiveSports(msisdn, username) {
        return await axios.post(`${environmentConfig.predictorService}/player-landing-page`, {
            msisdn: msisdn,
            username: username
        })
    }

    static getAllSports() {
        return {
            data: [
                {"SportName": "Soccer", "SportId": 1},
                {"SportName": "Rugby", "SportId": 250},
                {"SportName": "Cricket", "SportId": 3},
            ]
        }
    }

    static async getLeagues(sportId) {
        return await axios.post(`${environmentConfig.predictorService}/get-leagues`, {
            sportId: sportId,
            productId: 1
        })
    }

    static async getLeagueFixtures(msisdn, roundId) {
        return await axios.post(`${environmentConfig.predictorService}/round-fixtures`, {
            msisdn: msisdn,
            roundId: roundId,
        })
    }

    static async enterSoccerRoundPredictions(fixture, msisdn) {
        return await axios.post(`${environmentConfig.predictorService}/soccer/enter-predictions`, {
            msisdn: msisdn,
            roundId: fixture.roundId,
            soccerFixtureId: fixture.soccerFixtureId,
            eventTypeId: fixture.eventTypeId,
            team1Id: fixture.team1Id,
            team1Score: fixture.team1Score,
            team2Id: fixture.team2Id,
            team2Score: fixture.team2Score
        })
    }

    static async getPlayerRoundPredictionsLive(msisdn, roundId) {
        return await axios.post(`${environmentConfig.predictorService}/get-player-round-predictions`, {
            msisdn: msisdn,
            roundId: roundId
        })
    }


    static async getPlayerPublishedRounds(msisdn, sportId) {
        return await axios.post(`${environmentConfig.predictorService}/get-player-round-history`, {
            msisdn: msisdn,
            productId: 0,
            sportId: sportId,
            promo: 0,
            free: 0,
        })
    }

    static async getPlayerResults(msisdn) {
        return await axios.post(`${environmentConfig.predictorService}/get-players-winnings`, {
            msisdn: msisdn,
        })
    }

    static async getRoundLeaderboard(msisdn, roundId) {
        return await axios.post(`${environmentConfig.predictorService}/get-leaderboard`, {
            msisdn: msisdn,
            roundId: roundId
        })
    }


    static async enterRugbyRoundPredictions(fixture, msisdn) {
        return await axios.post(`${environmentConfig.predictorService}/rugby/enter-predictions`, {
            msisdn: msisdn,
            roundId: fixture.roundId,
            rugbyFixtureId: fixture.rugbyFixtureId,
            winMargin: fixture.winMargin,
            teamId: fixture.teamId,
            draw: fixture.draw,
        })
    }

    static async enterCricketRoundPredictions(fixture) {
        return await axios.post(`${environmentConfig.predictorService}/cricket/enter-predictions`, {
            msisdn: fixture.msisdn,
            roundId: fixture.roundId,
            cricketFixtureId: fixture.cricketFixtureId,
            eventTypeId: fixture.eventTypeId,
            teamId: fixture.teamId,
            battingWinMargin: fixture.battingWinMargin,
            bowlingWinMargin: fixture.bowlingWinMargin,
        })
    }

    static async getTeamInsights(leagueAbbreviation, teamId) {
        return await axios.post(`${environmentConfig.predictorService}/get-team-insights`, {
            leagueName: leagueAbbreviation,
            teamId: teamId
        })
    }

    static async getUserLandingPageStats(msisdn) {
        return await axios.post(`${environmentConfig.predictorService}/get-user-stats`, {
            msisdn: msisdn,
            promo: 0,
        })
    }
}

<template>
  <div class="body-container" v-if="!this.isLoading">
    <div v-if="this.$route.path === '/settings'">
      <div class="pb-1 settings-header">
        {{ formatString('settings_header_text') }}
      </div>
      <div class="d-flex align-items-center justify-content-center my-winnings-container">
        <img :src="require(`../brand/${brandConfig}/assets/main-banner.png`)" class="main-banner">
        <div v-if="this.userLandingStats.userStats.prizesWon"
             class="d-flex flex-column justify-content-center text-center my-winnings-circle primary-border-colour secondary-text">
          <div class="my-winnings-circle-data-amount-text">{{ userLandingStats.userStats.prizesWon }}</div>
          <div class="my-winnings-circle-data-label pt-2">{{ formatString('profile_prizes_won_label') }}</div>
        </div>
        <div v-if="!this.userLandingStats.userStats.prizesWon"
             class="d-flex flex-column justify-content-center text-center user-settings-play-circle">
          <div class="user-settings-play-circle-text primary-text">
            {{ formatString('profile_play_text') }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-around text-center p-3">
        <div
            class="d-flex flex-column flex-wrap col-4 total-season-points-container primary-bg-colour primary-text justify-content-center">
          <div class="p-1 total-season-points-text">
            {{ formatString('profile_total_season_points_header') }}
          </div>
          <div class="flex-wrap pt-2 total-season-points-results">
            {{ this.checkUndefinedValue(userLandingStats.userStats.totalSeasonPoints) }}
          </div>
        </div>
        <div
            class="d-flex flex-column flex-wrap col-4 rounds-entered-container primary-bg-colour primary-text justify-content-center">
          <div class="rounds-entered-text p-1">
            {{ formatString('profile_rounds_entered_header') }}
          </div>
          <div class=" pt-2 rounds-entered-results">
            {{ this.checkUndefinedValue(userLandingStats.userStats.roundsPreviouslyEntered) }}
          </div>
        </div>
        <div
            class="d-flex col-4 top-twenty-container primary-bg-colour primary-text d-flex flex-wrap flex-column justify-content-center">
          <div class="top-twenty-text p-1">
            {{ formatString('profile_top_hundred_header') }}
          </div>
          <div class="pt-2 top-twenty-results">
            {{ this.checkUndefinedValue(userLandingStats.userStats.numberOfTopHundreds) }}
          </div>
        </div>
      </div>
      <div class="d-flex flex-column text-center pt-1 pb-3 m-1">
        <button @click="changeUsernameButtonClicked" class="primary-text secondary-bg-colour edit-profile-button">
          {{ formatString('settings_change_username_button_text') }}
        </button>
        <button @click="notificationsButtonClicked" class="primary-text secondary-bg-colour edit-profile-button">
          {{ formatString('settings_change_notifications_button_text') }}
        </button>
        <button @click="cancelPackageButtonClicked" class="primary-text secondary-bg-colour edit-profile-button">
          {{ formatString('settings_change_cancel_package_button_text') }}
        </button>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {translationsFormatter} from "@/functions/translationsFormatter";

export default {
  name: "UserSettings",
  data() {
    return {
      settingsVisible: true,
    }
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'user', 'userLandingStats'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders);
    },
    changeUsernameButtonClicked() {
      this.$router.push('/settings/username');
      this.settingsVisible = false;
    },
    notificationsButtonClicked() {
      this.$router.push('/settings/notifications');
      this.settingsVisible = false;
    },
    cancelPackageButtonClicked() {
      this.$router.push('/settings/unsubscribe');
      this.settingsVisible = false;
    },
    checkUndefinedValue(number) {
      return number === undefined ? 0 : number;
    },
  },
}
</script>

<style scoped>
.body-container {
  overflow-y: scroll;
  padding-bottom: 50px;
  overflow-x: hidden;
  background-color: #e8e8e8;
  background-size: 100%;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.settings-header {
  font-size: 14px;
  font-weight: 750;
  padding-left: 3px;
  padding-top: 10px;
}

.main-banner {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.my-winnings-container {
  position: relative;
}

.my-winnings-circle {
  position: absolute;
  z-index: 1;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  background-color: #FFFFFF;
  border: solid 5px;
}

.my-winnings-circle-data-amount-text {
  font-size: 34px;
  font-weight: 700;
}

.my-winnings-circle-data-label {
  margin-top: -10px;
  font-weight: 750;
  font-size: 12px;
}

.user-settings-play-circle {
  position: absolute;
  z-index: 1;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #05a105;
  color: white;
}

.user-settings-play-circle-text {
  font-size: 40px;
  font-weight: 700;
}

.total-season-points-container {
  height: 100px;
  margin-right: 7px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 10px;
}

.rounds-entered-container {
  height: 100px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 10px;
}

.top-twenty-container {
  height: 100px;
  border-radius: 10px;
  margin-left: 7px;
  font-weight: bold;
  font-size: 10px;
}

.total-season-points-text, .rounds-entered-text, .top-twenty-text {
  height: 30%;
  font-size: 12px;
}

.total-season-points-results, .rounds-entered-results, .top-twenty-results {
  height: 50%;
  font-size: 30px;
}

.edit-profile-button {
  height: 50px;
  border-radius: 10px;
  margin-bottom: 15px;
  font-weight: 600;
  border: 0;
}

@media screen and (max-width: 382px) {
  .total-season-points-text, .rounds-entered-text, .top-twenty-text {
    height: 30%;
    font-size: 10px;
  }
}

</style>

import UpstreamService from "@/services/upstreamService";
import store from "@/store";
import {setBrandConfig} from "@/functions/setBrandConfig";
import {environmentConfig} from "@/config/enviromentConfig";
import PredictorService from "@/services/predictorService";

export async function userAuthenticate(to, from, next) {
    if (store.state.user && store.state.userLandingStats) {
        return next();
    }

    store.commit('setIsLoading', true);
    const urlParams = new URLSearchParams(window.location.search);
    const localStorageToken = localStorage.getItem('token');
    const encryptedMsisdn = urlParams.get('encryptedMsisdn');
    const fantasyToken = urlParams.get('fantasyToken') ? urlParams.get('fantasyToken') : localStorageToken ? localStorageToken : undefined;
    if (encryptedMsisdn) {
        const headerEnrichedLoginToken = await UpstreamService.headerEnrichedLogin(encryptedMsisdn);
        store.commit('setIsLoading', false);
        await setUserData(headerEnrichedLoginToken, next);
    } else if (fantasyToken) {
        await setUserData(fantasyToken, next);
    } else {
        if (store.state.brandConfig === 'vc-za-en') window.open(`${environmentConfig.entryPortalHost}`, '_self');
        if (store.state.brandConfig === 'vc-egy-ar') await setUserData('0F3D9469-D581-4511-ABA1-0FF65259B54F', next);
        else {
            store.commit('setIsLoading', false);
            return next();
        }
    }
}

async function setUserData(token, next) {
    localStorage.setItem('token', token);
    const user = (await UpstreamService.getUser(`${token}`)).data;
    const msisdn = user.msisdn;
    setBrandConfig();
    const userLandingPageStats = (await PredictorService.getUserLandingPageStats(`${msisdn}`)).data;
    const activeSports = (await PredictorService.getActiveSports(msisdn, user.username)).data;
    const roundsEntered = filterRoundsEntered(activeSports);
    store.commit('setUserLandingStats', userLandingPageStats);

    if (user.grantAccess) {
        await UpstreamService.trackLogin(msisdn)
        store.commit('setUser', user);
        store.commit('setRoundsEntered', roundsEntered);
        store.commit('setIsLoading', false);
        return next();
    } else {
        if (store.state.brandConfig === 'vc-za-en') window.open(`${environmentConfig.entryPortalHost}`, '_self');
        else return next();
    }
}

function filterRoundsEntered(activeSports) {
    const roundsEntered = activeSports.filter(function (sport) {
        return sport.HasPredictions === true && sport.Promo === false && sport.Free === false;
    })
    return roundsEntered.length;

}

<template>
  <div class="body-container" v-if="!this.isLoading">
    <div class="d-flex flex-column">
      <div
          class="d-flex flex-column justify-content-center text-center primary-bg-colour primary-text my-results-header-container">
        <img @click="backButtonClicked()" :src="require(`../brand/${brandConfig}/assets/left-keyboard-arrow.png`)"
             class="go-back-key">
        {{ formatString('my_results_primary_header_text', [this.selectedSport]) }}
      </div>
      <div
          class="d-flex flex-column justify-content-center text-center p-4 secondary-bg-colour primary-text results-secondary-header">
        {{ formatString('my_results_secondary_header_text') }}
      </div>
      <div class="d-flex flex-row justify-content-start">
        <div class="flex-column align-items-center text-center pt-1 p-1 select-leagues-text"
             v-for="(sport) in allSports" :key="sport.id">
          <div
              :class="sportSelected(sport) ? 'sport-name-highlighted primary-bg-colour  primary-text col-2' : 'sport-name-default col-2'"
              @click="changeSport(sport)">
            {{ sport.SportName }}
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center text-center pt-2" v-if="noRoundsEnteredMessageAlert">
        <img class="col-6" src="../assets/game/flag-icon.png">
        <div>{{ formatString('my_results_no_rounds_entered_text') }}</div>
      </div>
      <router-view>
        <div class="wrap">
          <div class="user-results-container">
            <div class="d-flex flex-column sport-round-predictions-results pt-2" v-for="(leagueResult) in userResults"
                 :key="leagueResult.id">
              <div class="d-flex flex-row align-items-center round-header secondary-bg-colour primary-text">
                <div class="d-flex flex-row align-items-center col-6">
                  <img class="league-icon-img" :src="this.findLeagueLogoImageModule(leagueResult[0].LeagueName)">
                  <div class="round-number-text">{{ leagueResult[0].LeagueName.toUpperCase() }}</div>
                </div>
                <div class="d-flex flex-row justify-content-end round-date col-6">
                  {{ moment(leagueResult[0].StartDate).format('Do MMMM YYYY') }}
                </div>
              </div>
              <div class="pt-2 table-responsive">
                <table class="table-borderless">
                  <thead>
                  <tr class="table-headers text-center">
                    <th scope="col">{{ formatString('my_results_table_header_teams') }}</th>
                    <th scope="col">{{ formatString('my_results_table_header_result') }}</th>
                    <th scope="col">{{ formatString('my_results_table_header_prediction') }}</th>
                    <th class="your-points-header secondary-text" scope="col">
                      {{ formatString('my_results_table_header_points') }}
                    </th>
                  </tr>
                  </thead>
                  <tbody class="table-body text-center m-1" v-for="match in leagueResult" :key="match.id">
                  <tr class="table-row">
                    <td class="d-flex flex-row justify-content-center">
                      <div class="d-flex flex-column align-items-center text-center">
                        <img class="badge-icon-img" :src="this.findTeamImageModule(match.Team1Name)[0]">
                        <div v-if="displayClubName" class="team-name text-center">
                          {{ this.findTeamImageModule(match.Team1Name)[1] }}
                        </div>
                      </div>
                      <div class="d-flex flex-column align-items-center text-center">
                        <img class="badge-icon-img" :src="this.findTeamImageModule(match.Team2Name)[0]">
                        <div v-if="displayClubName" class="team-name text-center">
                          {{ this.findTeamImageModule(match.Team2Name)[1] }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div v-if="this.selectedSport === 'Cricket'" class="d-flex flex-column align-items-center">
                        <div v-if="match.WinnerBattingWinMargin || match.WinnerBowlingWinMargin">
                          <img class="badge-icon-img" :src="this.findTeamImageModule(match.Name)">
                          <div>
                            {{ this.displayCricketWinMargin(match.WinnerBowlingWinMargin, match.WinnerBattingWinMargin) }}
                          </div>
                        </div>
                        <div class="secondary-text" v-if="!match.WinnerBattingWinMargin && !match.WinnerBowlingWinMargin">
                          {{ formatString('my_results_no_result_text') }}
                        </div>
                      </div>
                      <div v-if="match.Team1Result != null">
                        {{ match.Team1Result }}-{{ match.Team2Result }}
                      </div>
                      <div class="secondary-text" v-if="match.Team1Result === null">
                        {{ formatString('my_results_no_result_text') }}
                      </div>
                    </td>
                    <td>
                      <div v-if="this.selectedSport === 'Cricket'" class="d-flex flex-column align-items-center">
                        <img class="badge-icon-img" :src="this.findTeamImageModule(match.PredictedWinningTeam)[0]">
                        <div v-if="displayClubName">{{ this.findTeamImageModule(match.PredictedWinningTeam)[1] }}</div>
                        <div>{{ this.displayCricketWinMargin(match.BowlingWinMargin, match.BattingWinMargin) }}</div>
                      </div>
                      <div v-if="this.selectedSport === 'Soccer'">{{ match.Team1Score }}-{{ match.Team2Score }}</div>
                      <div v-if="this.selectedSport === 'Rugby'">
                        <div v-if="match.WinMargin != null">
                          {{ match.WinMargin }} ({{ shortenRugbyTeamNames(match.UsersPickWinningTeamName) }})
                        </div>
                        <div v-if="match.WinMargin === null">
                          {{ formatString('make_predictions_draw_text') }}
                        </div>
                      </div>
                    </td>
                    <td class="user-points-text secondary-text">
                      <div v-if="match.Points != null">
                        {{ match.Points }}
                      </div>
                      <div v-if="match.Points === null">
                        n/a
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </router-view>
    </div>
  </div>
</template>

<script>
import PredictorService from "@/services/predictorService";
import {mapMutations, mapState} from "vuex";
import {translationsFormatter} from "@/functions/translationsFormatter";
import moment from "moment";

export default {
  name: "MyResults",
  data() {
    return {
      userPredictions: [],
      roundPredictionsResultsTable: [],
      displayClubName: false,
      noRoundsEnteredMessageAlert: false,
    }
  },
  created: function () {
    this.moment = moment;
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'user', 'selectedSport', 'sportId'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setSelectedSport', 'setSportId']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders);
    },
    displayCricketWinMargin(winRuns, bowlingWickets) {
      if (winRuns > 25 || bowlingWickets > 5) {
        return 'Thrashing'
      } else if (10 < winRuns < 26 || 2 < bowlingWickets < 6) {
        return 'Comfortable'
      } else if (0 < winRuns < 11 || bowlingWickets < 3) {
        return 'Narrow'
      }
    },
    backButtonClicked() {
      this.$router.push({name: 'MyRounds'});
    },
    findLeagueLogoImageModule(leagueName) {
      leagueName = leagueName ? leagueName : 'placeholder'
      let teamNameReformat = leagueName.split(" ").join("-").toLowerCase()
      try {
        return [require(`../assets/leagueLogos/${teamNameReformat}-logo-default.png`)]
      } catch (e) {
        return [require(`../assets/game/default-badge.png`)]
      }
    },
    findTeamImageModule(teamName) {
      teamName = teamName ? teamName : 'placeholder'
      let teamNameReformat = teamName.split(" ").join("-").toLowerCase()
      try {
        return [require(`../assets/teamBadges/${this.selectedSport.toLowerCase()}/${teamNameReformat}-logo-icon.png`)]
      } catch (e) {
        return [require(`../assets/game/default-badge.png`), this.displayTeamName(teamName)]
      }
    },
    displayTeamName(teamName) {
      this.displayClubName = true
      return teamName
    },
    getAllSports() {
      let allSportsResponse = PredictorService.getAllSports()
      this.allSports = allSportsResponse.data
    },
    async changeSport(sport) {
      this.setIsLoading(true);
      let resultsResponse = await PredictorService.getPlayerPublishedRounds(`${this.user.msisdn}`, `${sport.SportId}`);
      this.userPredictions = resultsResponse.data.recordset;
      this.checkForNoResults();
      this.setSelectedSport(sport.SportName)
      this.sortUserRounds();
      this.$router.push({name: 'MyResults', params: {sportId: sport.SportId}});
      this.setIsLoading(false);
    },
    sportSelected(sport) {
      if (this.selectedSport === sport.SportName) {
        return true
      }
    },
    sortUserRounds() {
      this.userResults = this.userPredictions.reduce((roundsResults, item) => {
        const round = (roundsResults[item.RoundID] || []);
        round.push(item);
        roundsResults[item.RoundID] = round;
        return roundsResults;
      }, {});
      this.userResults = Object.keys(this.userResults).map((k) => this.userResults[k])
      this.userResults.reverse()
    },
    checkForNoResults() {
      this.noRoundsEnteredMessageAlert = this.userPredictions.length === 0;
    },
    setDefaultSport() {
      const sportMap = {
        'Soccer': 1,
        'Rugby': 250,
        'Cricket': 3
      };

      if (this.selectedSport === undefined) {
        this.setSelectedSport('Soccer');
        this.setSportId(1);
      } else if (sportMap[this.selectedSport] !== undefined) {
        this.setSelectedSport(this.selectedSport);
        this.setSportId(sportMap[this.selectedSport]);
      }
    },
    shortenRugbyTeamNames(teamName) {
      teamName = teamName.replace(' Rugby', "");
      return teamName
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    this.setDefaultSport();
    this.getAllSports();
    let resultsResponse = await PredictorService.getPlayerPublishedRounds(`${this.user.msisdn}`, `${this.sportId}`);
    this.userPredictions = resultsResponse.data.recordset;
    this.sortUserRounds();
    this.checkForNoResults();
    this.setIsLoading(false);
  },
}
</script>

<style scoped>
.body-container {
  overflow-y: scroll;
  padding-bottom: 50px;
  overflow-x: hidden;
  background-color: #e8e8e8;
  background-size: 100%;
  background-repeat: no-repeat;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.user-results-container {
  overflow-y: scroll;
  height: 65vh;
}

::-webkit-scrollbar {
  display: none;
}

table {
  width: 100%;
  margin-bottom: 1rem;
}

.my-results-header-container {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  height: 45px;
}

.go-back-key {
  position: absolute;
  height: 20px;
  left: 27px;
  top: 13px;
}

.results-secondary-header {
  height: 45px;
  border-radius: 0 0 20px 20px;
  font-size: 14px;
  font-weight: 500;
}

.sports-tab {
  color: #a8a8a8;
  font-weight: 600;
  padding-left: 6px;
}

.round-header {
  border-radius: 8px;
  font-weight: 600;
  height: 34px;
  font-size: 12px;
}

.league-icon-img {
  margin-left: 8px;
  height: 20px;
}

.badge-icon-img {
  width: 24px;
  margin: 2px;
}

.round-number-text {
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table-headers {
  font-size: 10px;
  font-weight: 600;
}

.table-body {
  font-size: 10px;
  font-weight: 500;
}

.table-row {
  border-bottom: 0.2px solid #a8a8a8;
  height: 40px
}

.user-points-text {
  font-weight: 600;
}

td {
  text-align: center;
  vertical-align: middle;
}

.select-leagues-text {
  font-weight: 600;
}

.sport-name-highlighted {
  width: 100%;
  border-radius: 15px;
  padding: 4px;
  font-size: 14px;
}

.sport-name-default {
  margin-top: 2px;
  color: #a8a8a8;
  font-size: 14px;
}

.round-date {
  padding-right: 10px;
}

.reverse-order {
  display: flex;
  flex-direction: column-reverse;
}

.team-name {
  font-size: 6px;
}


</style>

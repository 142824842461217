<template>
  <div class="body-container" v-if="!this.isLoading">
    <div class="d-flex flex-column">
      <div class="pt-2 my-profile-header-text">
        {{ formatString('profile_header_text') }}
      </div>
      <div class="d-flex align-items-center justify-content-center my-winnings-container">
        <img :src="this.fetchBanner()" class="main-banner">
        <div v-if="userLandingStats.userStats.prizesWon" class="text-center my-winnings-circle primary-border-colour secondary-text">
          <div class="pt-1 my-winnings-circle-data-amount-text">{{ userLandingStats.userStats.prizesWon }}</div>
          <div class="pt-1 my-winnings-circle-data-label">{{ formatString('profile_prizes_won_label') }}</div>
        </div>
        <div v-if="!userLandingStats.userStats.prizesWon" class="text-center my-profile-play-circle">
          <div @click="playCircleClicked()" class="my-profile-play-circle-text primary-text"><strong>{{ formatString('profile_play_text') }}</strong></div>
        </div>
      </div>
      <div class="d-flex justify-content-around text-center p-2">
        <div class="d-flex flex-column flex-wrap col-4 total-season-points-container primary-bg-colour primary-text justify-content-center">
          <div class="p-2 total-season-points-text">
            {{ formatString('profile_total_season_points_header') }}
          </div>
          <div class="flex-wrap pt-2 total-season-points-result">
            {{ this.checkUndefinedValue(userLandingStats.userStats.totalSeasonPoints) }}
          </div>
        </div>
        <div class="d-flex flex-column flex-wrap col-4 rounds-entered-container primary-bg-colour primary-text justify-content-center">
          <div class="rounds-entered-text p-2">
            {{ formatString('profile_rounds_entered_header') }}
          </div>
          <div class=" pt-2 rounds-entered-results">
            {{ this.checkUndefinedValue(userLandingStats.userStats.roundsPreviouslyEntered) }}
          </div>
        </div>
        <div class="d-flex col-4 top-twenty-container primary-bg-colour primary-text d-flex flex-wrap flex-column justify-content-center">
          <div class="data-won-text p-2">
            {{ formatString('profile_top_hundred_header') }}
          </div>
          <div class="pt-2 data-won-results">
            {{ this.checkUndefinedValue(userLandingStats.userStats.numberOfTopHundreds) }}
          </div>
        </div>
      </div>
      <div class=" mt-2 top-predictors-container">
        <div class="top-predictors-text">
          {{ formatString('profile_top_predictors_header') }}
        </div>
        <div class="d-flex flex-row justify-content-between p-1 text-center pt-2">
          <div class="flex-column align-items-center text-center col-2" v-for="(predictor) in topPredictors" :key="predictor.id">
            <img class="predictor-img" :src="require(`../brand/${brandConfig}/assets/user-icon.png`)"/>
            <p class="pt-1 top-predictor-username">{{ predictor.username }}</p>
            <p class="top-predictor-ranking">{{ predictor.rank }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import {translationsFormatter} from "@/functions/translationsFormatter";

export default {
  name: "LandingPage",
  data() {
    return {
      userStats: {},
      topPredictors: [],
      userStatsFontSize: '35px',
    }
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'user', 'userLandingStats'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setUser', 'setUserLandingStats']),
    formatString(string, ...placeholders) {
      return translationsFormatter(string, ...placeholders);
    },
    checkUndefinedValue(number) {
      return number === undefined ? 0 : number;
    },
    fetchBanner() {
      return require(`../brand/${this.brandConfig}/assets/main-banner.png`)
    },
    playCircleClicked() {
      this.$router.push('/sport-selection');
    },
    setFontSize() {
      let totalSeasonPointsLength = this.userLandingStats.userStats.totalSeasonPoints.toString().length;
      if (totalSeasonPointsLength >= 5) {
        this.userStatsFontSize = '25px'
      }
    },
    getTopPredictors() {
      this.topPredictors = this.userLandingStats.topPredictors
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    this.getTopPredictors()
    this.setFontSize();
    this.setIsLoading(false);
  },
}
</script>

<style scoped>

.body-container {
  overflow-y: scroll;
  padding-bottom: 50px;
  overflow-x: hidden;
  background-color: #e8e8e8;
  background-size: cover;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.my-profile-header-text {
  font-size: 13px;
  font-weight: 700
}

.main-banner {
  width: 100%;
}

.my-winnings-container {
  height: 80%;
  position: relative;
}

.my-winnings-circle {
  position: absolute;
  z-index: 1;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #FFFFFF;
  border: solid 5px;
}

.my-winnings-circle-data-amount-text {
  margin-top: 16px;
  font-size: 34px;
  font-weight: 700;
  left: 23%;
  top: 16%;
}

.my-winnings-circle-data-label {
  margin-top: -10px;
  font-weight: 750;
  font-size: 12px;
}

.my-profile-play-circle {
  position: absolute;
  z-index: 1;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #05a105;
  color: white;
}

.my-profile-play-circle-text {
  font-size: 40px;
  margin-top: 25%;
}

.total-season-points-container {
  height: 100px;
  margin-right: 7px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 10px;
}

.total-season-points-text, .rounds-entered-text, .data-won-text {
  height: 30px
}

.total-season-points-result, .rounds-entered-results, .data-won-results {
  /*font-size: v-bind('userStatsFontSize')*/
  font-size: 35px;
}

.rounds-entered-container {
  height: 100px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 10px;
}

.top-twenty-container {
  height: 100px;
  border-radius: 10px;
  margin-left: 7px;
  font-weight: bold;
  font-size: 10px;
}

.top-predictors-container {
  color: #FFFFFF;
  background-color: #464646;
  border-radius: 10px;
  height: 140px;
}

.top-predictors-text {
  font-weight: bold;
  margin-left: 10px;
  margin-top: 20px;
}

.predictor-img {
  height: 40px;
  width: 40px;
}

.top-predictor-username {
  font-size: 11px;
  height: 25%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.top-predictor-ranking {
  margin-top: -12px;
  font-size: 9px;
}

</style>
